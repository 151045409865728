import * as React from 'react';
import { useState } from 'react';
import { Appointments } from '@weddingspot/ws-api-client';
import { HotelFormConnected, VenueData } from '../appointments/RFPHotelForm';
import { ResponsiveModalContainer } from '../responsive-modal';

type Props = {
    show: boolean;
    csnVenueData: VenueData[];
    onHide: () => void;
    handleRoomCountSubmit: (params: object) => void;
    handleClickedSpecifyGuestRooms: () => void;
    defaultFormValue?: {
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
    };
    handleHotelsRecommendedForm: (count: number) => void;
    leadSource: Appointments.RFPleadSource;
};

enum Step {
    RFP,
    SUCCESS,
}

const Success = (props: Props) => {
    return (
        <div>
            Your request is sent! Cvent will send updates to the email address you provided.
            <div className='InquiryWidget--success--actions'>
                <button className='InquiryWidget--success--cta nx-button nx-button--primary' onClick={props.onHide}>
                    Done
                </button>
            </div>
        </div>
    );
};

export const RFPContainer = (props: Props) => {
    const { csnVenueData, handleHotelsRecommendedForm } = props;
    const [step, setStep] = useState<Step>(Step.RFP);

    return (
        <ResponsiveModalContainer
            show={props.show}
            headerContent='Request a Proposal'
            handleClickModalBackground={props.onHide}
            handleClickModalClose={props.onHide}
            className='is-RFP-container'
        >
            {step === Step.RFP && (
                <HotelFormConnected
                    type='venueProfile'
                    onClose={props.onHide}
                    onSuccess={() => {
                        handleHotelsRecommendedForm(csnVenueData.length);
                        setStep(Step.SUCCESS);
                    }}
                    handleRoomCountSubmit={props.handleRoomCountSubmit}
                    handleClickedSpecifyGuestRooms={props.handleClickedSpecifyGuestRooms}
                    csnVenueData={props.csnVenueData}
                    defaultFormValue={props.defaultFormValue}
                    leadSource={props.leadSource}
                />
            )}
            {step === Step.SUCCESS && <Success {...props} />}
        </ResponsiveModalContainer>
    );
};
