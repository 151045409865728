import * as React from 'react';
import cn from 'classnames';

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
    spinnerDiameter: number;
    spinnerColor: string;
}

/**
 * Renders a spinning svg. This is achieved by taking a diameter in pixels (D),
 * rendering a viewbox of 2*D, and a rectangle in the "12 o'clock" position and
 * rotatining it about the center of the viewbox at 10 times. The "arms" of the spinner
 * scale linearly with the spinner diameter.
 */
export class Spinner extends React.Component<SpinnerProps> {
    static defaultProps: Partial<SpinnerProps> = {
        spinnerDiameter: 50,
        spinnerColor: '#7d124b',
    };

    render() {
        const scale = this.props.spinnerDiameter / 50;

        const viewBoxSize = this.props.spinnerDiameter * 2;
        const spinnerInnerRadius = 12.5 * scale;
        const startingRectW = 5 * scale;
        const startingRectH = 15 * scale;
        const startingRectX = (viewBoxSize - startingRectW) / 2;
        const startingRectY = this.props.spinnerDiameter - (spinnerInnerRadius + startingRectH);

        return (
            <div className={cn('Spinner', this.props.className)}>
                <div className='LoadingSpinner--container'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        className='lds-spinner'
                        width={this.props.spinnerDiameter}
                        height={this.props.spinnerDiameter}
                        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
                        preserveAspectRatio='xMidYMid'
                        style={{ background: 'none' }}
                    >
                        <g transform={`rotate(0 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.9s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(36 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.8s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(72 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.7s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(108 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.6s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(144 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.5s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(180 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.4s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(216 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.3s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(252 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.2s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(288 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '-0.1s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                        <g transform={`rotate(324 ${this.props.spinnerDiameter} ${this.props.spinnerDiameter})`}>
                            <rect
                                x={startingRectX}
                                y={startingRectY}
                                rx='0'
                                ry='0'
                                width={startingRectW}
                                height={startingRectH}
                                fill={this.props.spinnerColor}
                            >
                                <animate
                                    {...{
                                        attributeName: 'opacity',
                                        values: '1;0',
                                        times: '0;1',
                                        dur: '1s',
                                        begin: '0s',
                                        repeatCount: 'indefinite',
                                    }}
                                />
                            </rect>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

export default Spinner;
