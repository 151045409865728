import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { change, FormState, InjectedFormProps, SubmissionError } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';

import { Appointments, Auth, VendorsApi } from '@weddingspot/ws-api-client';

import * as types from './types';
import { tryParseInt } from '../../utils/string';
import {
    AppointmentsActions,
    createSetIsExistingUserAction,
    createSetUnconnectedUserEmailAction,
    loadUserAuthStatusAndUserData,
    loadUserDataOnly,
    loadVenueData,
    setInquiryFormSubmittedData,
} from './actions';
import InquiryForm, { FormData, InquiryFormProps } from './InquiryForm';
import { DEFAULT_RD_MESSAGE, DEFAULT_WEDDING_MESSAGE, DEFAULT_WEDDING_WIDGET_MESSAGE, InquiryType } from './constants';
import { format } from 'date-fns';
import { LocalStorage, StorableDataKeys } from '../..';

/* Typedefs */
export interface OwnProps {
    // External
    // venueId may be undefined in case when form called from shopping cart
    venueId?: number;
    inquiryType?: InquiryType;
    userIsAuthed?: boolean;
    isWidgetFlow?: boolean;
    hideReasonForOutreachField?: boolean; // whether or not to show reason field
    hidePhoneNumberField?: boolean; // whether or not to show phone number field
    showPhoneAndHideReason?: boolean;

    // Reducer props
    authState: types.AuthState;
    formValues: FormData;
    datesFlexible: boolean;
    venueName: string;
    isQuoteRequest: boolean;
    requireGuestCount: boolean;
    availability: string[][] | null;
    blackoutDays: Array<number> | null;
    attributionLogo: string | null;

    // actions
    loadAuthStatus: (disabledMoments: Date[], datesFlexible: boolean) => void;
    loadUserData: (disabledMoments: Date[], datesFlexible: boolean) => void;
    updateFormUserData: (userData: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => void;
    updateForm: (field: string, value: any) => void;
    loadVenueData: (venueId: number) => void;

    // Callbacks
    handleSubmitInquirySuccess?: () => void;
    handleSubmitQuoteSuccess?: () => void;
    handleClickModalClose: () => void;
    isEmailExists?: () => void;
    customSubmitHandler?: (data: FormData) => void;
}

type InquiryFormContainerProps = OwnProps & Pick<InjectedFormProps<FormData, InquiryFormProps>, 'initialValues'>;

/**
 * Submit handler
 * returns a promise that resolves on successful request and
 * rejects on submission error from the API
 */
const submitHandlerGenerator = (props: InquiryFormContainerProps) => {
    return (data: FormData, dispatch: Dispatch<any>) => {
        let outreach_type: Appointments.OutreachTypes | undefined = undefined;
        if (props.inquiryType === InquiryType.WEDDING) {
            outreach_type = Appointments.OutreachTypes.WEDDING_FLOW;
        } else if (props.inquiryType === InquiryType.REHEARSAL_DINNER) {
            outreach_type = Appointments.OutreachTypes.RD_FLOW;
        }

        const {captcha_widget_id, ...submit_data} = data;

        let submitData = {
            ...submit_data,
            contact_reason: tryParseInt<number>(data.contact_reason, Appointments.ContactReasons.GENERAL_QUESTION as number),
            outreach_type: outreach_type,
            tos_shown: !props.userIsAuthed,
        };

        if (submitData.preferred_date_1) {
            submitData.preferred_date_1 = format(new Date(submitData.preferred_date_1), 'yyyy-MM-dd');
        } else {
            submitData.preferred_date_1 = undefined;
        }
        // @ts-ignore
        submitData.grecaptcha_response = grecaptcha.getResponse(captcha_widget_id);

        let promise = new Promise<void>((resolve, reject) => {
            !!props.venueId &&
                VendorsApi.sendInquiry(props.venueId, submitData)
                    .then((resp: any) => {
                        if (data.email) {
                            dispatch(createSetUnconnectedUserEmailAction(data.email));
                        }
                        dispatch(createSetIsExistingUserAction(!resp.new_user_id));
                        dispatch(setInquiryFormSubmittedData(submitData));

                        if (props.isQuoteRequest) {
                            if (!!props.handleSubmitQuoteSuccess) {
                                props.handleSubmitQuoteSuccess();
                            }
                        } else {
                            if (!!props.handleSubmitInquirySuccess) {
                                props.handleSubmitInquirySuccess();
                            }
                        }

                        (window as any).inquiry_id = resp.inquiry_id;
                        resolve();
                    })
                    .catch((response: any) => {
                        // TODO: move this into the API client
                        if (response && response.detail && response.detail.content && response.detail.content.errors) {
                            let errors = response.detail.content.errors;
                            if (errors.email && props.isEmailExists) {
                                props.isEmailExists();
                            }
                            reject(
                                new SubmissionError({
                                    ...errors,
                                    _error: 'Inquiry submission failed. Please review the errors and try again',
                                })
                            );
                        } else {
                            // Unhandled uknown error (i.e. network error, timeout and etc.)
                            reject(
                                new SubmissionError({
                                    _error: 'Inquiry submission failed. Please try again later',
                                })
                            );
                        }
                    });
        });

        return promise;
    };
};

const updateUserData = (dispatch: Dispatch<AppointmentsActions>, data: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => {
    if (data !== undefined) {
        let phone_number = data.phone_number;
        if (phone_number) {
            // just removing ext manually
            // because mask is not applying for initial data
            if (phone_number.indexOf(' ext') > -1) {
                phone_number = phone_number.slice(0, phone_number.indexOf(' ext'));
            }
            dispatch(change('InquiryForm', 'phone_number', phone_number));
        }

        if (data.dates_are_flexible) {
            if (!datesFlexible) {
                dispatch(change('InquiryForm', 'dates_flexible', true));
            }
        } else {
            if (data.preferred_event_date_1) {
                const preferred = new Date(data.preferred_event_date_1);
                if (preferred.getTime() > new Date().getTime() && !new Set(disabledMoments).has(preferred) && !datesFlexible) {
                    dispatch(change('InquiryForm', 'preferred_date_1', format(preferred, 'yyyy-MM-dd')));
                }
            }
        }
    }
};

class InquiryFormContainer extends React.Component<InquiryFormContainerProps, {}> {
    disabledMoments: Date[];

    constructor(props: InquiryFormContainerProps) {
        super(props);

        this.disabledMoments = [];
        if (!!props.availability) {
            this.disabledMoments = props.availability.filter((d) => d[1] === 'Reserved').map((d) => new Date(d[0]));
        }
    }

    componentDidMount() {
        const loggedIn = this.props.authState.userIsLoggedIn;
        !!this.props.venueId && this.props.loadVenueData(this.props.venueId);
        if (loggedIn === undefined) {
            this.props.loadAuthStatus(this.disabledMoments, this.props.datesFlexible);
        } else if (loggedIn && this.props.authState.userData === undefined) {
            this.props.loadUserData(this.disabledMoments, this.props.datesFlexible);
        } else if (loggedIn && !!this.props.authState.userData) {
            this.props.updateFormUserData(this.props.authState.userData, this.disabledMoments, this.props.datesFlexible);
        }
        if (!!this.props.isWidgetFlow) {
            this.props.updateForm('message', DEFAULT_WEDDING_WIDGET_MESSAGE);
            this.props.updateForm('contact_reason', '');
            this.props.updateForm('widget', '1');
        }
    }

    componentDidUpdate(prevProps: InquiryFormContainerProps) {
        if (prevProps.inquiryType !== this.props.inquiryType) {
            if (!!this.props.isWidgetFlow) {
                this.props.updateForm('message', DEFAULT_WEDDING_WIDGET_MESSAGE);
                this.props.updateForm('contact_reason', '');
            } else if (this.props.inquiryType === InquiryType.REHEARSAL_DINNER) {
                this.props.updateForm('message', DEFAULT_RD_MESSAGE);
                this.props.updateForm('contact_reason', `${Appointments.ContactReasons.REHEARSAL_DINNER_INQUIRY}`);
            } else {
                this.props.updateForm('message', DEFAULT_WEDDING_MESSAGE);
                this.props.updateForm('contact_reason', '');
            }
        }
    }

    render() {
        return (
            <div>
                <InquiryForm
                    initialValues={this.props.initialValues}
                    inquiryType={this.props.inquiryType}
                    userIsAuthed={this.props.userIsAuthed}
                    showSignupFields={!this.props.authState.userIsLoggedIn || false}
                    disabledDates={this.disabledMoments}
                    submitHandler={this.props.customSubmitHandler || submitHandlerGenerator(this.props)}
                    handleClickModalClose={this.props.handleClickModalClose}
                    requireGuestCount={this.props.requireGuestCount}
                    attributionLogo={this.props.attributionLogo}
                    clearDatesFlexible={() => {
                        this.props.updateForm('dates_flexible', false);
                    }}
                    clearPreferredDates={() => {
                        this.props.updateForm('preferred_date_1', '');
                    }}
                    setCaptchaWidgetId={(captcha_widget_id?: string) => {
                        this.props.updateForm('captcha_widget_id', captcha_widget_id);
                    }}
                    isWidgetFlow={this.props.isWidgetFlow}
                    hidePhoneNumberField={this.props.hidePhoneNumberField}
                    showPhoneAndHideReason={this.props.showPhoneAndHideReason}
                    hideReasonForOutreachField={this.props.hideReasonForOutreachField}
                />
            </div>
        );
    }
}

/* Note: we do the redux connect in the container module and not the component module
because typescript is weird about that */
const mapStateToProps = (state: types.InquiryModalCombinedState) => {
    const inquiryFormState = state.form['InquiryForm'] as FormState;

    return {
        authState: state.authState,
        initialValues: state.inquiryFormState.initialValues,
        formValues: inquiryFormState ? inquiryFormState.values : {},
        datesFlexible: inquiryFormState && inquiryFormState.values ? !!inquiryFormState.values.dates_flexible : false,
        ...state.inquiryFormContainerState,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<types.InquiryModalCombinedState, undefined, AppointmentsActions>) => ({
    loadAuthStatus: (disabledMoments: Date[], datesFlexible: boolean) => {
        loadUserAuthStatusAndUserData(dispatch)
            .then((resp) => {
                updateUserData(dispatch, resp, disabledMoments, datesFlexible);
            })
            .catch(() => {
                /* Do nothing*/
            });
    },
    loadUserData: (disabledMoments: Date[], datesFlexible: boolean) => {
        loadUserDataOnly(dispatch)
            .then((resp) => {
                updateUserData(dispatch, resp, disabledMoments, datesFlexible);
            })
            .catch(() => {
                /* Do nothing*/
            });
    },
    loadVenueData: (venueId: number) => {
        dispatch(loadVenueData(venueId));
    },
    updateFormUserData: (userData: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => {
        updateUserData(dispatch, userData, disabledMoments, datesFlexible);
    },
    updateForm: (field: string, value: any) => {
        dispatch(change('InquiryForm', field, value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(InquiryFormContainer);
