import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import cls from 'classnames';

import Button from '@cvent/carina/components/Button';
import CheckIcon from '@cvent/carina/components/Icon/Check';

import _styles from './AddToShoppingCartButton.module.scss';

interface Props {
    isInCart: boolean;
    removeVenue: (e: React.SyntheticEvent) => void;
    addVenue: (e: React.SyntheticEvent) => void;
    nonPrimary?: boolean;
    labelText?: string;
}

const AddToShoppingCartButton: React.FC<Props> = ({ isInCart, removeVenue, addVenue, nonPrimary, labelText = 'Select Venue' }) => {
    const [cartButtonHover, setCartButtonHover] = useState<boolean>(false);
    return (
        <div className={cls(_styles.AddToShoppingCartButton, { [_styles.AddToShoppingCartButtonInCart]: isInCart })}>
            <Button
                appearance={nonPrimary ? undefined : 'filled'}
                text={isInCart ? (cartButtonHover ? 'Remove from list' : 'Added to list') : 'Select Venue'}
                // @ts-ignore
                icon={() => {
                    return isInCart && !cartButtonHover ? CheckIcon : null;
                }}
                size='l'
                onClick={isInCart ? removeVenue : addVenue}
                onMouseEnter={() => !isMobile && setCartButtonHover(true)}
                onMouseLeave={() => !isMobile && setCartButtonHover(false)}
            />
        </div>
    );
};

export default AddToShoppingCartButton;
