import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Field,
    FieldArray,
    FormSection,
    getFormValues,
    InjectedFormProps,
    reduxForm,
    SubmissionError,
    WrappedFieldArrayProps,
} from 'redux-form';
import { Appointments, VendorsApi } from '@weddingspot/ws-api-client';

import { createValidator, email, phoneNumber, required } from '../../utils/validators';
import LoadingSpinner from '../LoadingSpinner';
import MaterialTextInput from '../form/MaterialTextInput';
import MaterialSelectInput from '../form/MaterialSelectInput';
import MaterialDatePickerInput from '../form/MaterialDatePickerInput';
import MaterialCheckboxInput from '../form/MaterialCheckboxInput';
import HybridMaterialTextArea from '../form/HybridMaterialTextArea';

import './RFPHotelForm.module.scss';
import { CountryConstants } from './InquiryHotelFormCountry';
import { cloneDeep } from '@weddingspot/ws-utils';
import { useSelector } from 'react-redux';
import { useWiderThan } from '../../utils/hooks';
import { RatingProps } from '../csn/Rating';
import { CartItem } from '../shopping-cart/CartItem';
import { addDays, addMonths } from '../../utils/date';
import { format } from 'date-fns';

const CVENT_PRIVACY_POLICY_URL = 'https://www.cvent.com/en/privacy-policy';
const CVENT_TERMS_OF_USE_URL = 'https://www.cvent.com/en/product-terms-of-use';

// BE CAREFUL, ConsentTextElement and ConsentTextHTML should be exactly the same!!!
const ConsentTextElement = (
    <div>
        By checking this box, I agree to share the personal information provided on this RFP to the selected hotels. To learn more, read
        Cvent’s
        <a target='_blank' href={CVENT_PRIVACY_POLICY_URL}>
            {' '}
            Privacy Policy{' '}
        </a>
        and
        <a target='_blank' href={CVENT_TERMS_OF_USE_URL}>
            {' '}
            Terms of Use.{' '}
        </a>
    </div>
);

const ConsentTextHTML = `
By checking this box, I agree to share the personal information
provided on this RFP to the selected hotels. To learn more, read Cvent’s
<a target="_blank" href="${CVENT_PRIVACY_POLICY_URL}"> Privacy Policy </a>
and
<a target="_blank" href="${CVENT_TERMS_OF_USE_URL}"> Terms of Use. </a>
`;
// BE CAREFUL, ConsentTextElement and ConsentTextHTML should be exactly the same!!!

/* Field level validators */
const requiredFieldValidator = createValidator(required, 'This field is required');
const arriveFieldValidator = createValidator(required, 'Arrival date must be on or before the departure date');
const departureFieldValidator = createValidator(required, 'Departure date must be on or after the arrival date');
const dueDateFieldValidator = createValidator(required, 'Please enter a response due date');
const phoneNumberValidator = createValidator(phoneNumber, 'Please enter a valid phone number');
const emailValidator = createValidator(email, 'Please enter a valid email address');
const isConsentedValidator = createValidator(
    required,
    'To submit. this request, you must allow Wedding Spot' + ' to save and share your contact information with the venues.'
);
const guestRoomsValidator = (value: number, values: FormData) => {
    if (!value) {
        const arrivingOn = values.arriving_on ? new Date(values.arriving_on) : null;
        const departingOn = values.arriving_on ? new Date(values.departing_on as string) : null;

        let daysDiff: number | null = null;
        if (arrivingOn && departingOn) {
            daysDiff = (departingOn.getTime() - arrivingOn.getTime()) / (1000 * 3600 * 24);
        }
        const extendedSectionAvailable = daysDiff === 2 || daysDiff === 1;

        if (!extendedSectionAvailable) {
            return 'Enter a valid number';
        }
        if (!values.sleeping_room_blocks?.length) {
            return (
                <div>
                    Enter a valid number <b>or</b>
                </div>
            );
        }
    }
    return undefined;
};

const FakeInputForCalendar = ({ value, onClick, className }: { value: string; onClick: () => void; className: string }) => (
    <input className={className} readOnly={true} onClick={onClick} value={value} />
);

export interface FormData {
    phone_number?: string;
    guest_count?: string;
    preferred_date_1?: string;
    dates_flexible?: boolean;
    message: string;
    widget?: boolean;
    country: string;
    arriving_on?: string;
    departing_on?: string;
    guest_rooms_per_night?: number;
    sleeping_room_blocks?: object[];
    captcha_widget_id?: string;
}

/* Constants */
const DEFAULT_MIN_DATE = addDays(new Date(), 2);
const DEFAULT_MAX_DATE = addMonths(new Date(), 60);

type RFPType = 'inquiry' | 'venueProfile' | 'email' | 'checkout';
export type VenueMeta = {
    name: string;
    location?: string;
    rating?: RatingProps;
    previewImg?: string;
};
export type VenueData = {
    id: string;
    meta?: VenueMeta;
};
type OwnProps = {
    csnVenueData: VenueData[];
    onClose: () => void;
    onSuccess?: () => void;
    handleRoomCountSubmit?: (params: object) => void;
    handleClickedSpecifyGuestRooms?: () => void;
    type: RFPType;
    defaultFormValue?: {
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
    };
    leadSource: Appointments.RFPleadSource;
    onSubmitting?: (submit: boolean) => void;
    onFormInit?: (el: HTMLFormElement | null) => void;
    hideButtons?: boolean;
};
type Props = InjectedFormProps<any> & OwnProps;

const sleepingRoomBlocks = (props: WrappedFieldArrayProps) => {
    const formValues = useSelector((state: any) => {
        return getFormValues(props.meta.form)(state);
    });

    const arrivingOn = formValues && formValues[`arriving_on`] ? new Date(formValues[`arriving_on`]) : undefined;
    const departingOn = formValues && formValues[`departing_on`] ? new Date(formValues[`departing_on`]) : undefined;

    let renderNext;

    if (arrivingOn && departingOn) {
        let daysDiff: number | null = null;
        if (arrivingOn && departingOn) {
            daysDiff = (departingOn.getTime() - arrivingOn.getTime()) / (1000 * 3600 * 24);
        }
        renderNext = daysDiff === 2 ? addDays(arrivingOn, 1) : null;
    }

    const renderFields = () => (
        <>
            <Field
                name='any'
                label='Any Room'
                component={MaterialTextInput}
                placeholder='Any Room'
                helper='2 People Per Guest Room'
                {...{ inputType: 'number' }}
            />
            <Field
                name='single'
                label='One Bed'
                component={MaterialTextInput}
                placeholder='One Person Per Guest Room'
                helper='2 People Per Guest Room'
                {...{ inputType: 'number' }}
            />
            <Field
                name='double'
                label='Two Beds'
                component={MaterialTextInput}
                placeholder='Two People Per Guest Room'
                helper='2 People Per Guest Room'
                {...{ inputType: 'number' }}
            />
            <Field
                name='suite'
                label='Suite'
                component={MaterialTextInput}
                placeholder='Suite'
                helper='2 People Per Guest Room'
                {...{ inputType: 'number' }}
            />
        </>
    );

    return (
        <>
            {arrivingOn && (
                <FormSection name='sleeping_room_blocks.0'>
                    <div className='is-row guestRoomsFieldsGroup'>
                        <div className={'guestRoomsFieldsGroup--title'}>{format(new Date(arrivingOn), 'yyyy-MM-dd')}</div>
                        <div className={'guestRoomsFieldsGroup--blockFields'}>{renderFields()}</div>
                    </div>
                </FormSection>
            )}
            {renderNext && (
                <FormSection name='sleeping_room_blocks.1'>
                    <div className='is-row guestRoomsFieldsGroup'>
                        <div className={'guestRoomsFieldsGroup--title'}>{format(new Date(renderNext), 'yyyy-MM-dd')}</div>
                        <div className={'guestRoomsFieldsGroup--blockFields'}>{renderFields()}</div>
                    </div>
                </FormSection>
            )}
        </>
    );
};

const HotelForm = (props: Props) => {
    const [submitting, setSubmitting] = useState(false);
    const [isSpecifyRoomCTAEnabled, setSpecifyRoomCTA] = useState(false);
    const [roomsPreferences, showRoomsPreferences] = useState(false);

    const formRef = React.useRef(null);

    useEffect(() => {
        props.onFormInit && props.onFormInit(formRef && formRef.current);
    }, []);

    React.useEffect(() => {
        props.onSubmitting && props.onSubmitting(submitting);
    }, [submitting]);

    React.useEffect(() => {
        if (props.defaultFormValue && ['venueProfile', 'checkout'].includes(props.type)) {
            props.initialize({
                email: props.defaultFormValue.email,
                first_name: props.defaultFormValue.firstName,
                last_name: props.defaultFormValue.lastName,
                phone_number: props.defaultFormValue.phoneNumber,
                country: 'US',
                additional_info:
                    props.type === 'venueProfile' || props.type === 'checkout'
                        ? 'Hi, I found you on Wedding Spot and I would like to receive more information about a hotel room block at your property.'
                        : '',
            });
        }
    }, []);

    const submitHandler = (_data: FormData) => {
        let data: any = cloneDeep(_data);

        data.lead_source = props.leadSource;

        data.phone = data.phone_number;
        data.inquiry_id = (window as any).inquiry_id;

        ['arriving_on', 'departing_on', 'response_due_date'].forEach((input) => {
            data[input] = format(new Date(data[input]), 'yyyy-MM-dd');
        });

        if (new Date(data.response_due_date) > new Date(data.arriving_on)) {
            throw new SubmissionError({
                response_due_date: 'response due date should be before arrival date',
            });
        }

        if (new Date(data.departing_on) < new Date(data.arriving_on)) {
            throw new SubmissionError({
                departing_on: 'Departure date should be ahead of arrival date',
            });
        }

        if ((new Date(data.departing_on).getTime() - new Date(data.departing_on).getTime()) / (1000 * 3600 * 24) > 90) {
            throw new SubmissionError({
                departing_on: 'Event duration cannot exceed 90 days',
            });
        }

        data.csn_venue_ids = props.csnVenueData.map((i) => i.id);
        data.consent_text = ConsentTextHTML;

        setSubmitting(true);
        return VendorsApi.sendInquiryHotel(data as any)
            .then(() => {
                props.onSuccess && props.onSuccess();
                setSubmitting(false);

                if (data.sleeping_room_blocks?.length) {
                    let params = {};
                    data.sleeping_room_blocks.map((day: object) => {
                        Object.keys(day).map((type) => (params[type] = true));
                    });

                    if (Object.keys(params).length) {
                        props.handleRoomCountSubmit && props.handleRoomCountSubmit(params);
                    }
                }
            })
            .catch((err) => {
                setSubmitting(false);
                const fieldsError = err.detail.content;
                throw new SubmissionError({
                    ...fieldsError,
                    _error: 'An error has occurred while submitting your request, please try again',
                });
            });
    };

    const formValues = useSelector((state: any) => {
        return getFormValues(props.form)(state);
    });

    const arrivingOn = formValues && formValues[`arriving_on`] ? new Date(formValues[`arriving_on`]) : undefined;
    const departingOn = formValues && formValues[`departing_on`] ? new Date(formValues[`departing_on`]) : undefined;

    useEffect(() => {
        let daysDiff: number | null = null;
        if (arrivingOn && departingOn) {
            daysDiff = (departingOn.getTime() - arrivingOn.getTime()) / (1000 * 3600 * 24);
        }
        const showRoomDetails = daysDiff === 2 || daysDiff === 1;
        setSpecifyRoomCTA(showRoomDetails);
        if (!showRoomDetails) {
            showRoomsPreferences(false);
        }
    }, [arrivingOn, departingOn]);

    const handleArrivingOnChange = (e: React.SyntheticEvent, newValue: string) => {
        const newDate = new Date(newValue);
        props.change('departing_on', addDays(newDate, 1));
        const v1 = addDays(newDate, -7);
        const v2 = addDays(DEFAULT_MIN_DATE, -1);
        props.change('response_due_date', v1.getTime() > v2.getTime() ? v1 : v2);
    };

    const handleDepartingOnChange = (e: React.SyntheticEvent, newValue: Date) => {
        props.change('departing_on', new Date(newValue));
    };

    const isSmall = !useWiderThan(768);

    const renderSleepingRoomBlocks = (after: 'guest_rooms' | 'city') => {
        return (
            (after === 'guest_rooms') === (props.type === 'inquiry' || isSmall) &&
            roomsPreferences && <FieldArray props={{}} name='sleeping_room_blocks' component={sleepingRoomBlocks} />
        );
    };

    const prevewData = props.csnVenueData.filter((v) => !!v.meta);
    const leftColData = prevewData.filter((v, idx) => idx % 2 == 0);
    const rightColData = prevewData.filter((v, idx) => idx % 2 == 1);

    return (
        <form ref={formRef} className='InquiryForm--hotel-form' onSubmit={props.handleSubmit(submitHandler)}>
            {prevewData.length > 0 && (
                <div className='InquiryForm--hotel-form--preview-container'>
                    <div className='InquiryForm--hotel-form--preview-container-inner'>
                        <div className='InquiryForm--hotel-form--preview-column InquiryForm--hotel-form--preview-column-left'>
                            {leftColData.map((v, idx) => {
                                const meta = v.meta as VenueMeta;

                                return (
                                    <CartItem
                                        id={v.id}
                                        title={meta.name}
                                        subtitle={meta.location}
                                        image={meta.previewImg}
                                        rating={meta.rating}
                                    />
                                );
                            })}
                        </div>
                        <div className='InquiryForm--hotel-form--preview-column InquiryForm--hotel-form--preview-column-right'>
                            {rightColData.map((v, idx) => {
                                const meta = v.meta as VenueMeta;

                                return (
                                    <CartItem
                                        id={v.id}
                                        title={meta.name}
                                        subtitle={meta.location}
                                        image={meta.previewImg}
                                        rating={meta.rating}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            <div className='InquiryForm--hotel-form--content-container'>
                {['venueProfile', 'email'].includes(props.type) && (
                    <Field
                        name='first_name'
                        label='First Name'
                        validate={[requiredFieldValidator]}
                        component={MaterialTextInput}
                        placeholder='First Name'
                    />
                )}

                {['venueProfile', 'email'].includes(props.type) && (
                    <Field
                        name='last_name'
                        label='Last Name'
                        validate={[requiredFieldValidator]}
                        component={MaterialTextInput}
                        placeholder='Last Name'
                    />
                )}

                {['venueProfile', 'email'].includes(props.type) && (
                    <Field
                        name='email'
                        label='Email Address'
                        validate={[requiredFieldValidator, emailValidator]}
                        component={MaterialTextInput}
                        placeholder='Email Address'
                    />
                )}

                <Field
                    name='phone_number'
                    label='Phone number'
                    validate={[requiredFieldValidator, phoneNumberValidator]}
                    component={MaterialTextInput}
                    placeholder='Phone number'
                    {...{ mask: '999-999-9999', inputType: 'tel' }}
                />
                <Field
                    name='arriving_on'
                    label='Arriving on'
                    validate={[arriveFieldValidator]}
                    component={MaterialDatePickerInput}
                    {...{
                        customInput: <FakeInputForCalendar {...({} as any)} />,
                        minDate: DEFAULT_MIN_DATE,
                        maxDate: DEFAULT_MAX_DATE,
                        onChange: handleArrivingOnChange,
                    }}
                />
                <Field
                    name='departing_on'
                    label='Departing on'
                    validate={[departureFieldValidator]}
                    component={MaterialDatePickerInput}
                    {...{
                        customInput: <FakeInputForCalendar {...({} as any)} />,
                        minDate: DEFAULT_MIN_DATE,
                        maxDate: DEFAULT_MAX_DATE,
                        onChange: handleDepartingOnChange,
                    }}
                />

                <div className='grouped'>
                    <Field
                        name='guest_rooms_per_night'
                        label='Guest rooms per night'
                        component={MaterialTextInput}
                        validate={[guestRoomsValidator]}
                        placeholder='Guest rooms per night'
                        {...{ inputType: 'number' }}
                    />

                    {isSpecifyRoomCTAEnabled && (
                        <a
                            className='specifyRoomsCTA'
                            onClick={() => {
                                showRoomsPreferences((v) => !v);
                                props.handleClickedSpecifyGuestRooms && props.handleClickedSpecifyGuestRooms();
                            }}
                        >
                            Specify Guest Rooms
                        </a>
                    )}
                </div>

                {renderSleepingRoomBlocks('guest_rooms')}

                <Field name='city' label='City' validate={[requiredFieldValidator]} component={MaterialTextInput} placeholder='City' />

                {renderSleepingRoomBlocks('city')}

                <Field
                    name='country'
                    label='Country'
                    validate={[requiredFieldValidator]}
                    component={MaterialSelectInput}
                    choices={CountryConstants.map(({ value, name }) => [value, name])}
                    placeholder='Country'
                />
                <Field
                    name='response_due_date'
                    label='Response due date'
                    validate={[dueDateFieldValidator]}
                    component={MaterialDatePickerInput}
                    {...{
                        customInput: <FakeInputForCalendar {...({} as any)} />,
                        minDate: addDays(DEFAULT_MIN_DATE, -1),
                        maxDate: DEFAULT_MAX_DATE,
                    }}
                />
                <Field
                    name='additional_info'
                    label='Tell us about your event'
                    component={HybridMaterialTextArea}
                    placeholder='Tell us about your event'
                />

                <Field name='is_consented' label={ConsentTextElement} validate={[isConsentedValidator]} component={MaterialCheckboxInput} />

                {!props.hideButtons && (
                    <div className='is-row'>
                        {props.error && <div className='is-error generic-error'>{props.error}</div>}
                        <button className='nx-button nx-button--second' type='button' onClick={props.onClose}>
                            Cancel
                        </button>

                        <div className='rfp-spinner-overlay'>
                            <button className='nx-button nx-button--primary'>Submit</button>
                            {submitting && (
                                <div className='overlayBackdrop'>
                                    <LoadingSpinner />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export const InquiryHotelFormConnected = reduxForm<FormData, OwnProps>({
    form: 'InquiryForm', // Be careful! I am using the same form name for auto data pass (e.g. user phone)
    touchOnBlur: false,
    destroyOnUnmount: false, // Prevent the form from being clearned on when navigating away
})(HotelForm);

// We have two different reduxForm connect because
// in one case we need to take info from prev inquiryStep, and in another pass it directly from props
export const HotelFormConnected = reduxForm<FormData, OwnProps>({
    form: 'RFPForm',
    touchOnBlur: false,
})(HotelForm);
