import * as React from 'react';
import { reduxForm, Field, FormErrors, InjectedFormProps, FormSubmitHandler } from 'redux-form';
import { Appointments, getCookieValue } from '@weddingspot/ws-api-client';

import { VENUE_CONTACT_REASONS, InquiryType, TERMS_URL, PRIVACY_POLICY_URL, YOUR_PRIVACY_CHOICES_URL, YOUR_PRIVACY_CHOICES } from './constants';
import { pairwise } from '../../utils/array';
import { required, email, phoneNumber, invalidGuestCount, createValidator } from '../../utils/validators';
import LoadingSpinner from '../LoadingSpinner';
import MaterialTextInput from '../form/MaterialTextInput';
import MaterialSelectInput from '../form/MaterialSelectInput';
import MaterialDatePickerInput from '../form/MaterialDatePickerInput';
import MaterialCheckboxInput from '../form/MaterialCheckboxInput';
import HybridMaterialTextArea from '../form/HybridMaterialTextArea';

import './scss/inquiry-form.module.scss';
import { addDays, addMonths } from '../../utils/date';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { StorableDataKeys } from '../..';
import CaptchaCheckbox from '../form/CaptchaCheckbox';
import {v4 as uuidv4} from 'uuid';

/* Field level validators */
const requiredFieldValidator = createValidator(required, 'This field is required');
const phoneNumberValidator = createValidator(phoneNumber, 'Please enter a valid phone number');
const guestCountValidator = createValidator(invalidGuestCount, 'Please enter a valid guest count');
const emailValidator = createValidator(email, 'Please enter a valid email address');
const captchaValidator = createValidator(required, 'Captcha verification failed');

/**
 * Form level validator:
 * Sync validation function for the entire form. This is where you want to add logic
 * that has
 */
const formValidator = (formData: FormData) => {
    const errors: FormErrors<FormData> = {};

    const contactOther = formData.contact_reason === `${Appointments.ContactReasons.OTHER}`;
    const contactReason = formData.contact_reason_other;
    if (contactOther && !contactReason) {
        errors.contact_reason_other = 'Please specify a reason';
    }
    const datesFlexible = formData.dates_flexible;
    const preferredDate = formData.preferred_date_1;
    if (!datesFlexible && !preferredDate) {
        errors.preferred_date_1 = "Please specify your preferred wedding date or check 'My dates are flexible'";
    }
    return errors;
};

const FakeInputForCalendar = ({ value, onClick, className }: { value: string; onClick: () => void; className: string }, props: InquiryFormProps) => (
    <input className={className} readOnly={true} onClick={onClick} value={value} 
    aria-label={`Preferred ${props.inquiryType === InquiryType.REHEARSAL_DINNER ? 'event' : 'wedding'} date`}/>
);

/* Typedefs */
export interface FormData {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
    guest_count?: string;
    contact_reason: string;
    contact_reason_other?: string;
    preferred_date_1?: string;
    dates_flexible?: boolean;
    message: string;
    widget?: boolean;
    country: string;
    captcha_widget_id?: string;
}

interface OwnProps {
    // The type of inquiry being sent (defaults to weddings)
    inquiryType?: InquiryType;

    // Whether or not to show signup fields (first name, last name)
    showSignupFields?: boolean;

    // If true shows contact reason select, if false shows contact reason freeform field
    showContactReasonField?: boolean;

    // Whether or not the user is logged in
    userIsAuthed?: boolean;

    // The min date to allowed to be selected in calendar
    minDate?: Date;

    // The max date to allowed to be selected in calendar
    maxDate?: Date;

    // Specific dates to disable in date picker
    disabledDates?: Date[];

    // Function for enabling/disabling dates on picker (true = allow; false = block)
    filterDate?: (m: Date) => boolean;

    // Whether or not guest count field should be required
    requireGuestCount: boolean;

    // Attribution image to show @ the bottom of the form
    attributionLogo: string | null;

    // Form submission handler
    submitHandler: FormSubmitHandler;

    // Function for clearing dates flexible (via props)
    clearDatesFlexible: () => void;

    // Function for clearing preffered wedding date from from (via props)
    clearPreferredDates: () => void;

    handleClickModalClose: () => void;

    setCaptchaWidgetId: (captcha_widget_id?: string) => void;

    // Whether or not this inquiry form is opened withing widget flow
    isWidgetFlow?: boolean;
    widget?: boolean;

    // whether or not to show reason field
    hideReasonForOutreachField?: boolean;

    // whether or not to show phone number field
    hidePhoneNumberField?: boolean;
    showPhoneAndHideReason?: boolean;
    isEmailExists?: () => void;
}

export type InquiryFormProps = InjectedFormProps<FormData, OwnProps> & OwnProps;

/* Constants */
const PLACEHOLDER_MESSAGE: string = 'Enter your inquiry for the venue coordinator here.';
const DEFAULT_MIN_DATE = addDays(new Date(), 1);
const DEFAULT_MAX_DATE = addMonths(new Date(), 60);
const orderedFields = [
    {
        name: 'first_name',
        component: (props: InquiryFormProps) =>
            props.showSignupFields && (
                <Field
                    name='first_name'
                    label='First name'
                    validate={[requiredFieldValidator]}
                    component={MaterialTextInput}
                    placeholder='First name'
                    ariaLabel='First Name'
                />
            ),
    },
    {
        name: 'last_name',
        component: (props: InquiryFormProps) =>
            props.showSignupFields && (
                <Field
                    name='last_name'
                    label='Last name'
                    validate={[requiredFieldValidator]}
                    component={MaterialTextInput}
                    placeholder='Last name'
                    ariaLabel='Last Name'
                />
            ),
    },
    {
        name: 'email',
        component: (props: InquiryFormProps) =>
            props.showSignupFields && (
                <Field
                    name='email'
                    label='Email'
                    validate={[requiredFieldValidator, emailValidator]}
                    component={MaterialTextInput}
                    placeholder='Email'
                    ariaLabel='Email'
                />
            ),
    },
    {
        name: 'phone_number',
        component: (props: InquiryFormProps) =>
            !props.hidePhoneNumberField && (
                <Field
                    name='phone_number'
                    label={props.showPhoneAndHideReason ? 'Phone number (optional)' : 'Phone number'}
                    validate={[...(props.showPhoneAndHideReason ? [] : [requiredFieldValidator]), phoneNumberValidator]}
                    component={MaterialTextInput}
                    placeholder='Phone number'
                    {...{ mask: '999-999-9999' }}
                    ariaLabel='Phone number'
                />
            ),
    },
    {
        name: 'guest_count',
        component: (props: InquiryFormProps) => (
            <Field
                validate={props.requireGuestCount ? [requiredFieldValidator, guestCountValidator] : [guestCountValidator]}
                name='guest_count'
                label={`Number of guests${props.requireGuestCount ? '' : ' (optional)'}`}
                component={MaterialTextInput}
                placeholder='Number of guests (optional)'
                {...{ inputType: 'number' }}
                ariaLabel='Number of guests'
            />
        ),
    },
    {
        name: 'contact_reason_group',
        component: (props: InquiryFormProps) => {
            if (props.hideReasonForOutreachField || props.showPhoneAndHideReason) {
                return;
            }

            return !props.showContactReasonField ? (
                <Field
                    name='contact_reason'
                    validate={[]}
                    label='Reason for contacting venue'
                    component={MaterialSelectInput}
                    placeholder='Reason for contacting venue'
                    ariaLabel='Reason for contacting venue'
                    {...{
                        choices: VENUE_CONTACT_REASONS.filter(({ types }) => {
                            return types.indexOf(props.inquiryType || InquiryType.WEDDING) !== -1;
                        }).map(({ label, value }) => {
                            return [String(value), label] as [string, string];
                        }),
                    }}
                />
            ) : (
                <Field
                    name='contact_reason_other'
                    label='Enter a contact reason here'
                    component={MaterialTextInput}
                    placeholder='Contact reason'
                    ariaLabel='Reason for contacting venue'
                />
            );
        },
    },
    {
        // Plz notice, preferred_data_group component expect to be the last element of list.
        // If u create some other component below this, behavior will be really weird!
        name: 'preferred_date_group',
        component: (props: InquiryFormProps) => (
            <div className='InquiryForm--date-fields'>
                <Field
                    name='preferred_date_1'
                    label={`Preferred ${props.inquiryType === InquiryType.REHEARSAL_DINNER ? 'event' : 'wedding'} date`}
                    component={MaterialDatePickerInput}
                    {...{
                        customInput: <FakeInputForCalendar {...({props} as any)} />,
                        minDate: props.minDate || DEFAULT_MIN_DATE,
                        maxDate: props.maxDate || DEFAULT_MAX_DATE,
                        excludeDates: props.disabledDates || [],
                        filterDate: props.filterDate,
                        onChange: () => {
                            props.clearDatesFlexible();
                        },
                    }}
                />
                <Field
                    name='dates_flexible'
                    label='My dates are flexible'
                    component={MaterialCheckboxInput}
                    onChange={props.clearPreferredDates}
                />
            </div>
        ),
    },
];

const InquiryForm: React.SFC<InquiryFormProps> = (props) => {
    const visibleFields = orderedFields.filter((field) => field.component(props));

    const { handleSubmit, submitHandler, error, submitting, pristine, isWidgetFlow } = props;
    const [recaptchaRendered, setRecaptchaRendered] = React.useState(false);
    const [uuid, setUuid] = React.useState<string>('');

    React.useEffect(() => {
        if(!recaptchaRendered){
            setUuid(uuidv4().replace(/-/gi, ''));
            setRecaptchaRendered(true);
        }
    }, []);

    React.useEffect( () => {
        if (uuid != '') {
            const grecaptcha = document.createElement('script');
            grecaptcha.type = 'text/javascript';
            grecaptcha.async = true;
            grecaptcha.defer = true;
            grecaptcha.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallbackInquiryForm_${uuid}&render=explicit`;
            document.head.appendChild(grecaptcha);

            const callbackMethod = document.createElement('script');
            callbackMethod.type = 'text/javascript';
            callbackMethod.text = `
            var recaptchaCallbackInquiryForm_${uuid} = function () {
                if(document.getElementById('inquiry-form-recaptcha-${uuid}')) {
                    let recaptcha_widget_id_inquiry_form = grecaptcha.render("inquiry-form-recaptcha-${uuid}",{
                        'sitekey':"${RECAPTCHA_SITE_KEY}", 
                        'callback': function (response) {
                            document.getElementById('inquiry-form-hidden-input-${uuid}').click(); 
                        },
                        'expired-callback': function (response) {
                            document.getElementById('inquiry-form-hidden-input-${uuid}').click(); 
                        }
                    })
                    if(document.getElementById('inquiry-form-hidden-widget-id-${uuid}')) {
                        document.getElementById('inquiry-form-hidden-widget-id-${uuid}').dataset.widget_id = recaptcha_widget_id_inquiry_form;
                    }
                }
            }
            `;
            document.head.appendChild(callbackMethod);
            setRecaptchaRendered(true);
        }
    }, [uuid]);

    const fieldsList = pairwise(visibleFields).map(([left, right], index) => {
        const isExtendable = !right && left.name === 'preferred_date_group';
        return (
            <div className='InquiryForm--row' key={index}>
                <div className={'InquiryForm--col InquiryForm--col--left ' + (isExtendable ? 'is-extendable' : '')}>
                    <div className='InquiryForm--col-content'>{left.component(props)}</div>
                </div>
                {right && (
                    <div className='InquiryForm--col InquiryForm--col--right'>
                        <div className='InquiryForm--col-content'>{right && right.component(props)}</div>
                    </div>
                )}
            </div>
        );
    });

    return (
        <form className='InquiryForm' onSubmit={handleSubmit(submitHandler)}>
            <div className='InquiryForm--fields'>
                <div className='InquiryForm--flex-container'>{fieldsList}</div>
                <div>
                    <Field
                        name='message'
                        label='Your message'
                        validate={[requiredFieldValidator]}
                        component={HybridMaterialTextArea}
                        placeholder={PLACEHOLDER_MESSAGE}
                        ariaLabel='Your message to the venue'
                    />
                </div>
                <div className='InquiryForm-hidden-widget'>
                    <Field name='widget' component='input' type='hidden' value='0' />
                </div>
            </div>

            {error && <div className='InquiryForm--error'>{error}</div>}

            {!props.userIsAuthed && (
                <div className='InquiryForm--tos'>
                    {'By submitting this inquiry, you accept our '}
                    <a href={TERMS_URL} target={!!isWidgetFlow ? '_blank' : '_self'}>
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href={PRIVACY_POLICY_URL} target={!!isWidgetFlow ? '_blank' : '_self'}>
                        Privacy Policy
                    </a>
                    .
                </div>
            )}

            {/* Dont show captcha to the logged-in user OR to those non-logged-in user, who has already */}
            {/* verified CAPTCHA once in the current session */}
            <div
                id={'inquiry-form-recaptcha-'+uuid}
                hidden={!props.showSignupFields || 
                (getCookieValue(StorableDataKeys.RECAPTCHA_VALIDATION_SUCCESSFUL) === '1')}
            />

            {/* Add hidden checkbox (to store the value whether CAPTCHA is clicked or not) ONLY for if */}
            {/* the user is non-logged in and has not already verified the CAPTCHA in the current session */}
            {(getCookieValue(StorableDataKeys.RECAPTCHA_VALIDATION_SUCCESSFUL) !== '1') && props.showSignupFields &&
                <>
                    <Field
                        name='captcha'
                        id={'inquiry-form-hidden-input-'+uuid}
                        component={CaptchaCheckbox} 
                        validate={[captchaValidator]}
                        ariaLabel='Captcha'
                        onChange={() => {
                            props.setCaptchaWidgetId(document.getElementById('inquiry-form-hidden-widget-id-'+uuid)?.dataset.widget_id);
                        }}
                    />
                    <Field
                        name='captcha_widget_id'
                        id={'inquiry-form-hidden-widget-id-'+uuid}
                        component='input'
                        label='Captcha Widget ID'
                        ariaLabel='Captcha Widget ID'
                        hidden={true}
                    />
                </>
            }

            <div className={'InquiryForm--cta-container'}>
                <div className='InquiryForm--cta-spinner-container'>
                    <button className='nx-button nx-button--primary' disabled={pristine || submitting}>
                        Send Message
                    </button>
                    <div className='InquiryForm--cta-container--close-text' onClick={props.handleClickModalClose}>
                        no thanks, take me back
                    </div>
                    {submitting && <LoadingSpinner />}
                </div>
            </div>

            {!props.userIsAuthed && !props.isWidgetFlow && (
                <div className='InquiryForm--ccpa'>
                    <a href={YOUR_PRIVACY_CHOICES_URL} target='_blank'>
                        {YOUR_PRIVACY_CHOICES}
                    </a>
                </div>
            )}

            {!!props.attributionLogo && (
                <div className='InquiryForm--attribution-logo'>
                    {'POWERED BY '}
                    <img src={props.attributionLogo} title={'Inquiry attribution source'} />
                </div>
            )}
        </form>
    );
};

export default reduxForm<FormData, OwnProps>({
    form: 'InquiryForm',
    validate: formValidator,
    touchOnBlur: false, // Prevents validation on blur
    destroyOnUnmount: false, // Prevent the form from being clearned on when navigating away
    initialValues: {
        country: 'US',
    },
})(InquiryForm);
