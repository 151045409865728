import * as React from 'react';

import InquiryFormContainer from './InquiryFormContainer';
import { InquiryType } from './constants';

interface Props {
    venueId: number;
    userIsAuthed?: boolean;
    isWidgetFlow?: boolean;
    inquiryType?: InquiryType;
    hideReasonForOutreachField?: boolean; // whether or not to show reason field
    hidePhoneNumberField?: boolean; // whether or not to show phone number field
    showPhoneAndHideReason?: boolean;
    handleInquirySubmitSuccess?: (isQuoteRequest: boolean) => void;
    handleClickModalClose: () => void;
    isEmailExists?: () => void;
}

export default class InquiryFormWidget extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleInquirySuccess = this.handleInquirySuccess.bind(this);
        this.handleQuoteSuccess = this.handleQuoteSuccess.bind(this);
    }

    render() {
        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--inquiry-form'>
                    <InquiryFormContainer
                        venueId={this.props.venueId}
                        inquiryType={this.props.inquiryType}
                        handleClickModalClose={this.props.handleClickModalClose}
                        handleSubmitInquirySuccess={this.handleInquirySuccess}
                        handleSubmitQuoteSuccess={this.handleQuoteSuccess}
                        userIsAuthed={this.props.userIsAuthed}
                        isWidgetFlow={this.props.isWidgetFlow}
                        hidePhoneNumberField={this.props.hidePhoneNumberField}
                        showPhoneAndHideReason={this.props.showPhoneAndHideReason}
                        hideReasonForOutreachField={this.props.hideReasonForOutreachField}
                        isEmailExists={this.props.isEmailExists}
                    />
                </div>
            </div>
        );
    }

    private handleInquirySuccess() {
        !!this.props.handleInquirySubmitSuccess && this.props.handleInquirySubmitSuccess(false);
    }

    private handleQuoteSuccess() {
        !!this.props.handleInquirySubmitSuccess && this.props.handleInquirySubmitSuccess(true);
    }
}
