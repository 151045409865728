import * as React from 'react';
import { InjectedFormProps, FormSubmitHandler } from 'redux-form';
import { DWT } from '@weddingspot/ws-api-client';

import * as types from './types';
import Step1, { FormData as Step1Data } from './DWTInquiryFormStep1';
import Step2, { FormData as Step2Data } from './DWTInquiryFormStep2';

import './scss/dwt-inquiry-form.module.scss';

export interface CombinedFormData extends Step1Data, Step2Data {}

interface OwnProps {
    // Show step2
    showStep2: boolean;

    // User data fully loaded
    userDataLoaded: boolean;

    // Whether or not to show username/password fields
    showSignupFields?: boolean;

    // Minimum date for validation
    minDate?: Date;

    // Maxium date for validation
    maxDate?: Date;

    // Dates that should be blacked out
    disabledDates?: Date[];

    // Funciton for disabling dates (true = enable, false = disable)
    filterDate?: (m: Date) => boolean;

    // Whether or not guest count is required
    requireGuestCount: boolean;

    // Logo to show for attribution
    attributionLogo: string | null;

    // Handle submit
    submitHandler: FormSubmitHandler;

    // Handlers for clearing date/dates flexible
    clearDatesFlexible: () => void;
    clearPreferredDates: () => void;
    setCaptchaWidgetId: (captcha_widget_id?: string) => void;

    // Current form step
    curStep: types.DWTInquiryFormStep;
    handleGoToStep1: () => void;
    handleGoToStep2: (partner1Role?: string, partner1FirstName?: string, partner1LastName?: string) => void;
}

export type DWTInquiryFormProps = OwnProps & Pick<InjectedFormProps, 'initialValues'>;

/**
 * Form used to submit inquiries
 */
const InquiryForm: React.SFC<DWTInquiryFormProps> = (props) => {
    const step2Handler = (values: Step1Data) => {
        // Pass data onto step 2 if the requestor is the bride or the groom
        if (values.role === `${DWT.DWTRoles.BRIDE}` || values.role === `${DWT.DWTRoles.GROOM}`) {
            props.handleGoToStep2(values.role, values.first_name, values.last_name);
        } else {
            props.handleGoToStep2();
        }
    };

    return (
        <div className='InquiryForm DWTInquiryForm'>
            {props.curStep === types.DWTInquiryFormStep.INQUIRY_DETAILS && (
                <Step1
                    initialValues={props.initialValues}
                    onSubmit={props.showStep2 ? step2Handler : props.submitHandler}
                    userDataLoaded={props.userDataLoaded}
                    hasNextStep={props.showStep2}
                    showSignupFields={props.showSignupFields}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    disabledDates={props.disabledDates}
                    filterDate={props.filterDate}
                    requireGuestCount={props.requireGuestCount}
                    clearDatesFlexible={props.clearDatesFlexible}
                    clearPreferredDates={props.clearPreferredDates}
                    attributionLogo={props.attributionLogo}
                />
            )}
            {props.showStep2 && props.curStep === types.DWTInquiryFormStep.COUPLE_DETAILS && (
                <Step2
                    initialValues={props.initialValues}
                    onSubmit={props.submitHandler}
                    attributionLogo={props.attributionLogo}
                    handleBackClick={props.handleGoToStep1}
                    setCaptchaWidgetId={props.setCaptchaWidgetId}
                />
            )}
        </div>
    );
};

export default InquiryForm;
