import * as React from 'react';

import StarHalfIcon from '@cvent/carina/components/Icon/StarHalf';
import StarFillIcon from '@cvent/carina/components/Icon/StarFill';
import DiamondHalfIcon from '@cvent/carina/components/Icon/DiamondHalf';
import DiamondFilledIcon from '@cvent/carina/components/Icon/DiamondFilled';

export interface RatingProps {
    type: 'DIAMONDS' | 'STARS';
    value: number;
    color?: string;
}

const Rating = (props: RatingProps) => {
    const { value, type, color } = props;
    return (
        <div>
            {[...Array(~~(value || 0))].map((_, i) => {
                return type == 'STARS' ? (
                    <StarFillIcon size={'s'} key={i} color={color} />
                ) : (
                    <DiamondFilledIcon size={'s'} key={i} color={color} />
                );
            })}
            {(value || 0) % 1 ? (
                type == 'STARS' ? (
                    <StarHalfIcon size={'s'} color={color} />
                ) : (
                    <DiamondHalfIcon size={'s'} color={color} />
                )
            ) : (
                ''
            )}
        </div>
    );
};

export default Rating;
