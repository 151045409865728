import { Auth, Venues } from '@weddingspot/ws-api-client';
import { FormReducer } from 'redux-form';

import { VenueRecommendationsState } from '../recommendations/types';
import { InquiryType } from './constants';

/**
 * Auth related state
 */
export interface AuthState {
    userIsLoggedIn?: boolean;
    userData?: Auth.UserInfo;
    unconnectedUserEmail?: string;
    isExistingUser?: boolean;
}

/**
 * State for just the InqiuryForm component
 */
export interface InquiryFormState {
    initialValues: {
        message?: string;
        preferred_date_1?: string;
        dates_flexible?: boolean;
        contact_reason?: string;
        country?: string;
        additional_info?: string;
    };
}

export interface InquiryFormContainerState {
    venueName: string;
    attributionLogo: string | null;
    isQuoteRequest: boolean;
    requireGuestCount: boolean;
    availability: string[][] | null;
    blackoutDays: number[] | null;
    userDataLoaded?: boolean;
}

export enum DWTInquiryFormStep {
    INQUIRY_DETAILS = 1,
    COUPLE_DETAILS = 2,
}

export interface DWTInquiryFormState {
    curStep: DWTInquiryFormStep;
    initialValues: {
        venue_id?: number;
        message?: string;
        preferred_wedding_date?: string;
        dates_flexible?: boolean;
        role?: number;
        country?: string;
        partner1_role?: number;
        partner1_first_name?: string;
        partner1_last_name?: string;
        partner2_role?: number;
        partner2_first_name?: string;
        partner2_last_name?: string;
    };
}

/**
 * Represents the different states the inquiry modal can be in
 */
export enum InquiryModalStage {
    INQUIRY_FORM = 1,
    PASSWORD_FORM,
    VENUE_RECOMMENDATIONS,
    ABANDONMENT,
    SUCCESS,
    HOTEL_RECOMMENDATIONS,
    HOTEL_RECOMMENDATIONS_RFP_FORM,
    SEE_NEARBY_HOTELS,
}

/**
 * State for just the InquiryModal components
 */
export interface InquiryModalState {
    inquiryFormSuccess: boolean;
    massInquiryFormSuccess: boolean;
    hotelsInquirySuccess: boolean;
    modernRFPFormSuccess: boolean;
    modernRFPFormVisited: boolean;
    inquiryFormData: object;
    passwordFormSuccess: boolean;
    passwordFormCancel: boolean;
    curStep: InquiryModalStage;
    prevStep?: InquiryModalStage;
    userEmail?: string;
    recommendations: Venues.VenueRecommendation[];
    recommendationsLoaded: boolean;
    hotels: Venues.HotelRecommendation[];
    hotelsLoaded: boolean;

    lastRecTypeRequested: InquiryType | null;
}

/**
 * Combined state for inquiry modal and subcomponents
 */
export interface InquiryModalCombinedState {
    venueRecommendations: VenueRecommendationsState;
    authState: AuthState;
    form: FormReducer;
    inquiryFormState: InquiryFormState;
    dwtInquiryFormState: DWTInquiryFormState;
    inquiryFormContainerState: InquiryFormContainerState;
    inquiryModalState: InquiryModalState;
}
