import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Dispatch } from 'redux';
import { AuthProvider } from '@weddingspot/ws-api-client';
import { SubmissionError } from 'redux-form';

import { createClearUnconnectedUserEmailAction } from './actions';
import ConnectAccountForm, { FormData } from './ConnectAccountForm';

interface OwnProps {
    userEmail: string;
    isMultipleInquiryFlow: boolean;

    skipHandler: () => void;
    onConnectSuccess?: () => void;
}

type ConnectAccountFormContainerProps = OwnProps & DispatchProp<any>;

const submitHandler = (data: FormData, dispatch: Dispatch<any>) => {
    return new Promise<void>((resolve, reject) => {
        AuthProvider.connectAccount(data.password)
            .then((_) => {
                dispatch(createClearUnconnectedUserEmailAction());
                resolve();
            })
            .catch((response: any) => {
                // TODO: move response unpacking into API client
                if (response?.detail?.content?.errors?.password?.length) {
                    reject(
                        new SubmissionError({
                            password: response.detail.content.errors.password[0],
                        })
                    );
                } else {
                    reject(
                        new SubmissionError({
                            _error: 'Could not set password, please try again later. Click "Skip" to continue.',
                        })
                    );
                }
            });
    });
};

const ConnectAccountFormContainer: React.SFC<ConnectAccountFormContainerProps> = (props) => {
    const customProps = {
        isMultipleInquiryFlow: props.isMultipleInquiryFlow,
        userEmail: props.userEmail,
        submitHandler,
        skipHandler: props.skipHandler,
    } as any; // Typescript hack to make custom fields work for decorated redux form

    // Wrap the submit handler in another promise that triggers a success handler
    // padded in via the container props
    const wrappedSubmitHandler = (data: FormData, dispatch: Dispatch<any>) => {
        return new Promise((resolve, reject) => {
            submitHandler(data, dispatch)
                .then((resp) => {
                    props.onConnectSuccess && props.onConnectSuccess();
                    resolve(resp);
                })
                .catch(reject);
        });
    };
    customProps.submitHandler = wrappedSubmitHandler;

    return (
        <div className='ConnectAccountFormContainer'>
            <ConnectAccountForm {...customProps} />
        </div>
    );
};

export default connect()(ConnectAccountFormContainer);
