import * as constants from './constants';
import * as types from './types';
import { AppointmentsActions, DWTFormSuccessAction, GoToInquiryStepAction, UserDataLoadedAction, VenueDataLoadedAction } from './actions';
import { FormAction, actionTypes as reduxFormActionTypes, reducer as formReducer } from 'redux-form';

import { venueRecommendations } from '../recommendations/reducers';

const defaultAuthState: types.AuthState = {
    userIsLoggedIn: undefined,
    userData: undefined,
    unconnectedUserEmail: undefined,
    isExistingUser: undefined,
};

export function authReducer(state: types.AuthState = defaultAuthState, action: AppointmentsActions) {
    switch (action.type) {
        case constants.USER_AUTH_STATUS_UPDATED:
            return {
                ...state,
                userIsLoggedIn: action.payload,
            };
        case constants.USER_DATA_LOADED:
            return {
                ...state,
                userData: action.payload,
            };
        case constants.UNCONNECT_USER_EMAIL_SET:
            return {
                ...state,
                unconnectedUserEmail: action.payload,
            };
        case constants.UNCONNECT_USER_EMAIL_CLEAR:
            return {
                ...state,
                unconnectedUserEmail: undefined,
            };
        case constants.IS_EXISTING_USER_SET:
            return {
                ...state,
                isExistingUser: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
}

const defaultInquiryFormState: types.InquiryFormState = {
    initialValues: {
        message: constants.DEFAULT_WEDDING_MESSAGE,
        preferred_date_1: '',
        dates_flexible: false,
        country: 'US',
        additional_info:
            'Hi, I found you on Wedding Spot and I would like to receive more information about a hotel room block at your property.',
    },
};

/**
 * Reducer function that handles actions from the InquiryForm redux form
 */
function inquiryFormReducer(state: types.InquiryFormState = defaultInquiryFormState) {
    return { ...state };
}

const defaultDWTInquiryFormState: types.DWTInquiryFormState = {
    curStep: types.DWTInquiryFormStep.INQUIRY_DETAILS,
    initialValues: {
        message: 'Hi,\nI found you on Wedding Spot and I would' + ' like to receive more information about having a wedding at your venue.',
        preferred_wedding_date: undefined,
        dates_flexible: false,
    },
};

function dwtInquiryFormReducer(
    state: types.DWTInquiryFormState = defaultDWTInquiryFormState,
    action: GoToInquiryStepAction | DWTFormSuccessAction
) {
    switch (action.type) {
        case constants.GO_TO_DWT_FORM_STEP:
            return {
                ...state,
                curStep: action.payload,
            };
        case constants.DWT_FORM_SUCCESS:
            // Reset the form to the first step
            return {
                ...state,
                curStep: types.DWTInquiryFormStep.INQUIRY_DETAILS,
            };
        default:
            return { ...state };
    }
}

const defaultInquiryFormContainerState: types.InquiryFormContainerState = {
    venueName: '',
    isQuoteRequest: false,
    requireGuestCount: true,
    attributionLogo: null,
    availability: [],
    blackoutDays: [],
    userDataLoaded: false,
};

export function inquiryFormContainerReducer(
    state: types.InquiryFormContainerState = defaultInquiryFormContainerState,
    action: VenueDataLoadedAction | UserDataLoadedAction
) {
    switch (action.type) {
        case constants.VENUE_DATA_LOADED:
            const payload = (action as VenueDataLoadedAction).payload;
            return {
                ...state,
                isQuoteRequest: !payload.is_priced,
                venueName: payload.name,
                requireGuestCount: payload.require_inquiry_guest_count,
                availability: payload.availability,
                blackoutDays: payload.appointment_blackout_days,
                attributionLogo: payload.inquiry_attribution_img_url,
            };
        case constants.USER_DATA_LOADED:
            return {
                ...state,
                userDataLoaded: true,
            };
        default:
            return state;
    }
}

/**
 * Reducer for the top-level InquiryModal component
 */
const defaultInqiuryModalState: types.InquiryModalState = {
    curStep: types.InquiryModalStage.INQUIRY_FORM,
    modernRFPFormSuccess: false,
    modernRFPFormVisited: false,
    inquiryFormSuccess: false,
    massInquiryFormSuccess: false,
    hotelsInquirySuccess: false,
    inquiryFormData: {},
    passwordFormSuccess: false,
    passwordFormCancel: false,
    userEmail: undefined,
    recommendations: [],
    recommendationsLoaded: false,
    hotels: [],
    hotelsLoaded: false,
    lastRecTypeRequested: null,
};

/**
 * Reducer function that handles inqiury modal actions
 */
export function inquiryModalReducer(
    state: types.InquiryModalState = defaultInqiuryModalState,
    action: AppointmentsActions | FormAction | GoToInquiryStepAction
) {
    switch (action.type) {
        case constants.INQUIRY_MODAL_TRANSITION_INQUIRY_FORM:
            return {
                ...state,
                curStep: types.InquiryModalStage.INQUIRY_FORM,
            };
        case constants.INQUIRY_MODAL_TRANSITION_PASSWORD:
            return {
                ...state,
                curStep: types.InquiryModalStage.PASSWORD_FORM,
            };
        case constants.INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS:
            return {
                ...state,
                curStep: types.InquiryModalStage.VENUE_RECOMMENDATIONS,
            };
        case constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS:
            return {
                ...state,
                curStep: types.InquiryModalStage.HOTEL_RECOMMENDATIONS,
            };
        case constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM:
            return {
                ...state,
                curStep: types.InquiryModalStage.HOTEL_RECOMMENDATIONS_RFP_FORM,
                modernRFPFormVisited: true,
            };
        case constants.INQUIRY_MODAL_TRANSITION_ABANDONMENT:
            return {
                ...state,
                curStep: types.InquiryModalStage.ABANDONMENT,
            };
        case constants.INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN:
            return {
                ...state,
                // for some reason INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN call 3 times,
                // and we need to prevent override of prev step
                prevStep: state.curStep === types.InquiryModalStage.SUCCESS ? state.prevStep : state.curStep,
                curStep: types.InquiryModalStage.SUCCESS,
            };
        case constants.INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN:
            return {
                ...state,
                curStep: types.InquiryModalStage.SEE_NEARBY_HOTELS,
            };
        case constants.INQUIRY_MODAL_RESET:
            return { ...defaultInqiuryModalState };
        case constants.DWT_FORM_SUCCESS:
            return {
                ...state,
                inquiryFormSuccess: true,
            };
        case constants.INQUIRY_FORM_SUBMITTED_DATA_SET:
            const { payload } = action as FormAction;
            return {
                ...state,
                inquiryFormData: payload,
            };
        case reduxFormActionTypes.SET_SUBMIT_SUCCEEDED:
            const {
                meta: { form },
                error,
            } = action as FormAction;
            if (form === 'InquiryForm' && !error) {
                return {
                    ...state,
                    inquiryFormSuccess: true,
                    modernRFPFormSuccess: state.modernRFPFormVisited,
                };
            } else if (form === 'OutreachPushForm' && !error) {
                return {
                    ...state,
                    massInquiryFormSuccess: true,
                };
            } else if (form === 'OutreachHotelsForm' && !error) {
                return {
                    ...state,
                    hotelsInquirySuccess: true,
                };
            } else if (form === 'ConnectAccountForm' && !error) {
                return {
                    ...state,
                    passwordFormSuccess: true,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}

// TODO: Figure out a better way to export reducers to be used downstream...
export const inquiryModalReducers = {
    venueRecommendations,
    authState: authReducer,
    form: formReducer, // Note: redux-form requires this key be named "form"
    inquiryFormState: inquiryFormReducer,
    dwtInquiryFormState: dwtInquiryFormReducer,
    inquiryFormContainerState: inquiryFormContainerReducer,
    inquiryModalState: inquiryModalReducer,
};
