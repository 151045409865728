import React from 'react';
// @ts-ignore
import StarRating, { IconSizes } from '@cvent-reviews-ui/star-rating';
import { Breakpoints } from '@cvent/carina/components/Breakpoints/Breakpoints';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import format from 'date-fns/format';
import NewTag from './NewTag';
import './scss/Review.module.scss';
import { Venues } from '@weddingspot/ws-api-client';
import cn from 'classnames';

const ReviewCard: React.FC<Venues.ReviewProps> = ({ rating, review, showAsCard, isNew, showTimestamp, isLast}) => {
    const cardStyle = cn({'ReviewCard--reviewCard': showAsCard}, {'ReviewCard--reviewPlain': !showAsCard}, {'ReviewCard--reviewPlain--borderBottom': !showAsCard && !isLast});
    const starStyle = cn({'ReviewCard--adminStar': showAsCard}, {'ReviewCard--star': !showAsCard});

    return (
        <Row>
            <Col width={showAsCard ? 3/4: 1}>
                <div className={cardStyle}>
                    {/* Topmost segment of ReviewCard showing Star rating & New tag (if required)  */}
                    <div style={{ display: 'flex' }}>
                        <Row margin={-8}>
                            <Col width='fill'>
                                <div className='ReviewCard--ratingNamePlaceholder'>
                                    <div className={starStyle}>
                                        <StarRating
                                            id='star-rating'
                                            value={rating.stars}
                                            readonly={rating.readonly}
                                            size={IconSizes.tiny}
                                            name={rating.name}
                                        />
                                    </div>
                                    <div className='ReviewCard--authorName' data-dd-privacy="mask" data-dd-action-name="Review Author">{review.author}</div>
                                </div>
                            </Col>
                            <Col width='content'>{isNew && <NewTag />}</Col>
                        </Row>
                    </div>
                    <Breakpoints />
                    <Row>
                        <p style={{overflowWrap: "anywhere"}}> {review.text} </p>
                    </Row>

                    
                </div>
            </Col>
            {showTimestamp && (
                <Col width='content'>
                    <div className='ReviewCard--timestamp'>{format(new Date(review.submittedTimestamp), 'MMM dd, yyyy hh:mm a')}</div>
                </Col>
            )}
        </Row>
    );
};

export default ReviewCard;
