import * as React from 'react';
import { reduxForm, Field, FormErrors, InjectedFormProps, change } from 'redux-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DWT, AuthProvider } from '@weddingspot/ws-api-client';

import { pairwise } from '../../utils/array';
import { required, email, phoneNumber, invalidGuestCount, createValidator } from '../../utils/validators';
import MaterialTextInput from '../form/MaterialTextInput';
import MaterialSelectInput from '../form/MaterialSelectInput';
import MaterialDatePickerInput from '../form/MaterialDatePickerInput';
import MaterialCheckboxInput from '../form/MaterialCheckboxInput';
import HybridMaterialTextArea from '../form/HybridMaterialTextArea';

import './scss/dwt-inquiry-form.module.scss';
import { addMonths } from '../../utils/date';

/* Field level validators */
const requiredFieldValidator = createValidator(required, 'This field is required');
const phoneNumberValidator = createValidator(phoneNumber, 'Please enter a valid phone number');
const guestCountValidator = createValidator(invalidGuestCount, 'Please enter a valid guest count');
const emailValidator = createValidator(email, 'Please enter a valid email address');

/**
 * Form level validator:
 * Sync validation function for the entire form. This is where you want to add logic
 * that has
 */
const formValidator = (formData: FormData) => {
    const errors: FormErrors<FormData> = {};

    const datesFlexible = formData.dates_flexible;
    const preferredDate = formData.preferred_wedding_date;
    if (!datesFlexible && !preferredDate) {
        errors.preferred_wedding_date = "Please specify your preferred wedding date or check 'My dates are flexible'";
    }
    const guestCount = formData.guest_count;
    if (!guestCount) {
        errors.guest_count = "This field is required";
    }
    return errors;
};

const FakeInputForCalendar = ({ value, onClick, className }: { value: string; onClick: () => void; className: string }) => (
    <input className={className} readOnly={true} onClick={onClick} value={value} aria-label='Preferred wedding date'/>
);

/**
 * Server-side validation for form
 */
const asyncValidate = (formData: FormData) => {
    if (!!formData.email) {
        return AuthProvider.checkEmailAvailable(formData.email).catch(() => {
            throw {
                email: 'A user with this email address already exists, please log in or reset password to continue.',
            };
        });
    }
    return Promise.resolve();
};

/* Typedefs */
export interface FormData {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
    guest_count?: number;
    role: string; // Should be a number but select component only supports numbers as of now
    preferred_wedding_date?: string;
    dates_flexible?: boolean;
    message: string;
}

interface OwnProps {
    // Whether or not user data has fully loaded
    userDataLoaded: boolean;

    // Whether or not there is a step2
    hasNextStep: boolean;

    // Whether or not to show username/password fields
    showSignupFields?: boolean;

    // Minimum date for validation
    minDate?: Date;

    // Maxium date for validation
    maxDate?: Date;

    // Dates that should be blacked out
    disabledDates?: Date[];

    // Funciton for disabling dates (true = enable, false = disable)
    filterDate?: (m: Date) => boolean;

    // Whether or not guest count is required
    requireGuestCount: boolean;

    // Handlers for clearing date/dates flexible
    clearDatesFlexible: () => void;
    clearPreferredDates: () => void;

    // Logo to show for attribution
    attributionLogo: string | null;
    updateForm: (field: string, value: any) => void;
}

export type DWTInquiryFormStep1Props = InjectedFormProps<FormData, OwnProps> & OwnProps;

/* Constants */
export const PLACEHOLDER_MESSAGE: string = 'Enter your inquiry for the venue coordinator here.';
const DEFAULT_MIN_DATE = addMonths(new Date(), 1);
const DEFAULT_MAX_DATE = addMonths(new Date(), 60);
const step1Fields = [
    (props: DWTInquiryFormStep1Props, key: number) =>
        props.showSignupFields && (
            <Field
                key={key}
                name='first_name'
                label='Your first name'
                validate={[requiredFieldValidator]}
                component={MaterialTextInput}
                placeholder='You first name'
                ariaLabel='First name'
            />
        ),
    (props: DWTInquiryFormStep1Props, key: number) =>
        props.showSignupFields && (
            <Field
                key={key}
                name='last_name'
                label='Your last name'
                validate={[requiredFieldValidator]}
                component={MaterialTextInput}
                placeholder='Your last name'
                ariaLabel='Last name'
            />
        ),
    (props: DWTInquiryFormStep1Props, key: number) =>
        props.showSignupFields && (
            <Field
                key={key}
                name='email'
                label='Email'
                validate={[requiredFieldValidator, emailValidator]}
                component={MaterialTextInput}
                placeholder='Email'
                ariaLabel='Email'
            />
        ),
    (props: DWTInquiryFormStep1Props, key: number) => (
        <Field
            key={key}
            name='phone_number'
            label='Phone number'
            validate={[requiredFieldValidator, phoneNumberValidator]}
            component={MaterialTextInput}
            placeholder='Phone number'
            ariaLabel='Phone number'
            {...{ mask: '999-999-9999' }}
        />
    ),
    (props: DWTInquiryFormStep1Props, key: number) => (
        <Field
            key={key}
            validate={[requiredFieldValidator, guestCountValidator]}
            name='guest_count'
            label={`Number of guests`}
            component={MaterialTextInput}
            placeholder='Number of guests'
            ariaLabel='Number of guests'
            {...{ inputType: 'number' }}
        />
    ),
    (props: DWTInquiryFormStep1Props, key: number) => {
        const allRoles = [DWT.DWTRoles.BRIDE, DWT.DWTRoles.GROOM, DWT.DWTRoles.PARENT, DWT.DWTRoles.OTHER];
        return (
            <div className='InquiryForm--relationship'>
                <Field
                    key={key}
                    name='role'
                    label='Relationship'
                    placeholder='Relationship'
                    component={MaterialSelectInput}
                    validate={[requiredFieldValidator]}
                    choices={allRoles.map((role) => {
                        if (role === DWT.DWTRoles.BRIDE) {
                            return [`${DWT.DWTRoles.BRIDE}`, "I'm the bride"];
                        } else if (role === DWT.DWTRoles.GROOM) {
                            return [`${DWT.DWTRoles.GROOM}`, "I'm the groom"];
                        } else if (role === DWT.DWTRoles.PARENT) {
                            return [`${DWT.DWTRoles.PARENT}`, "I'm a parent"];
                        } else {
                            return [`${DWT.DWTRoles.OTHER}`, "I'm a friend (or other)"];
                        }
                    })}
                    ariaLabel='Relationship'
                />
            </div>
        );
    },
    (props: DWTInquiryFormStep1Props, key: number) => (
        <div className='InquiryForm--date-fields'>
            {
                <Field
                    key={key}
                    name='preferred_wedding_date'
                    label='Preferred wedding date'
                    component={MaterialDatePickerInput}
                    {...{
                        customInput: <FakeInputForCalendar {...({} as any)} />,
                        minDate: props.minDate || DEFAULT_MIN_DATE,
                        maxDate: props.maxDate || DEFAULT_MAX_DATE,
                        excludeDates: props.disabledDates || [],
                        filterDate: props.filterDate,
                        onChange: () => {
                            props.clearDatesFlexible();
                            props.updateForm('dates_flexible', false);
                        },
                    }}
                    aria-label='Preferred wedding date'
                />
            }
            {!props.showSignupFields && (
                <Field
                    key={key + 1}
                    name='dates_flexible'
                    label='My dates are flexible'
                    component={MaterialCheckboxInput}
                    onChange={() => {
                        props.clearPreferredDates();
                        props.updateForm('preferred_wedding_date', '');
                    }}
                />
            )}
        </div>
    ),
    (props: DWTInquiryFormStep1Props, key: number) =>
        props.showSignupFields && (
            <div className='InquiryForm--standalone-flexible-wrapper'>
                <Field
                    key={key + 2}
                    name='dates_flexible'
                    label='My dates are flexible'
                    component={MaterialCheckboxInput}
                    onChange={() => {
                        props.clearPreferredDates();
                        props.updateForm('preferred_wedding_date', '');
                    }}
                />
            </div>
        ),
];

/**
 * Form used to submit inquiries
 */
let InquiryForm: React.SFC<DWTInquiryFormStep1Props> = (props) => {
    let _props: DWTInquiryFormStep1Props & { children?: React.ReactNode } = { ...props };
    if (_props.showSignupFields === undefined) {
        _props.showSignupFields = false;
    }

    const visibleFields = step1Fields.filter((renderedComp) => {
        return !!renderedComp(_props, 0);
    });

    const { handleSubmit, error, submitting, asyncValidating } = _props;

    return (
        <form className={'InquiryForm--form'} onSubmit={handleSubmit}>
            <div className='InquiryForm--fields Inquiry--fields__step-1'>
                <div className='InquiryForm--flex-container'>
                    {pairwise<any>(visibleFields).map(([left, right], idx) => {
                        return (
                            <div className='InquiryForm--row' key={idx}>
                                <div className='InquiryForm--col InquiryForm--col--left'>
                                    <div className='InquiryForm--col-content'>{left(_props, idx)}</div>
                                </div>
                                <div className='InquiryForm--col InquiryForm--col--right'>
                                    <div className='InquiryForm--col-content'>{!!right && right(_props, idx + 1)}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div>
                    <Field
                        name='message'
                        label='Your message'
                        validate={[requiredFieldValidator]}
                        component={HybridMaterialTextArea}
                        placeholder={PLACEHOLDER_MESSAGE}
                        ariaLabel='Your message to the venue'
                    />
                </div>
            </div>

            {error && <div className='InquiryForm--error'>{error}</div>}

            <div className={'InquiryForm--cta-container'}>
                {!!props.attributionLogo && (
                    <div className='InquiryForm--attribution-logo nx-hide-for-small-only'>
                        {'POWERED BY '}
                        <img src={props.attributionLogo} title={'Inquiry attribution source'} />
                    </div>
                )}

                <div className='InquiryForm--cta-spinner-container'>
                    <button className='nx-button nx-button--primary' disabled={submitting || !!asyncValidating || !props.userDataLoaded}>
                        {props.hasNextStep ? 'Next' : 'Send Inquiry'}
                    </button>
                </div>
            </div>

            {!!props.attributionLogo && (
                <div className='InquiryForm--attribution-logo nx-show-for-small-only'>
                    {'POWERED BY '}
                    <img src={props.attributionLogo} title={'Inquiry attribution source'} />
                </div>
            )}
        </form>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateForm: (field: string, value: any) => {
        dispatch(change('DWTInquiryForm', field, value));
    },
});

export default connect(null, mapDispatchToProps)(reduxForm<{}, OwnProps>({
    form: 'DWTInquiryForm',
    validate: formValidator,
    asyncValidate,
    asyncBlurFields: [],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(InquiryForm));
