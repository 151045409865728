import React from 'react';

import './scss/Review.module.scss';

interface OverallRatingProps {
    overallRating: number;
    totalCount: number;
}

const OverallRating: React.FC<OverallRatingProps> = ({ overallRating, totalCount }) => (
    <table className='overallRating'>
        <tbody>
            <tr>
                <td rowSpan={2} className='overallRating__star'>
                    <div className='overallRating__rating'>{overallRating.toFixed(1)}</div>
                </td>
                <td className='overallRating__outof'>out of 5</td>
            </tr>
            <tr>
                <td>
                    <div className='overallRating__totalCount'>
                        {totalCount} {totalCount > 1 ? 'reviews' : 'review'}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
);

export default OverallRating;
