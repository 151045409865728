import * as React from 'react';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import Button from '@cvent/carina/components/Button';
import styles from './Web2Case.module.scss';
import { IS_ALPHA, IS_DEV_ENV, IS_STAGING, CVENT_SALESFORCE_URL } from '../../config';

const INIT_VALUES = {
    name: '',
    city: '',
    email: '',
    state: '',
    phone: '',
    budget: '',
    guest: '',
    desc: '',
    description: '',
    signup: false,
};

let CONFIG = {
    web2caseUrl: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
    initData: {
        orgid: '00D00000000hXfW',
        retURL: 'https://www.wedding-spot.com/',
        recordType: '0122G000000rvuGQAQ',
        '00N00000006yRRJ': 'Wedding Spot',
        '00N2G00000CxUzi': 'Couple',
    },
};

if (IS_DEV_ENV || IS_STAGING || IS_ALPHA) {
    CONFIG.web2caseUrl = CVENT_SALESFORCE_URL + 'servlet/servlet.WebToCase?encoding=UTF-8';
    CONFIG.initData = {
        orgid: '00D7i0000003hMG',
        retURL: 'https://wedding-spot.com/venues',
        recordType: '0127i000001kHvJAAU',
        '00N00000006yRRJ': 'Wedding Spot',
        '00N2G00000CxUzi': 'Couple',
    };
}

export function Web2CaseForm(props: { handleCancel: () => void }) {
    const { handleCancel } = props;
    const handleSubmitForm = (formData: any) => {
        const decsription = `City : ${formData.city}
                            State : ${formData.state}
                            Approximate Budget : ${formData.budget}
                            Approximate Guest Count : ${formData.guest}
                            I agree to sign up for free Wedding Spot Account : ${formData.signup}
                            Description : ${formData.desc}`;
        const url = CONFIG.web2caseUrl;
        fetch(url, {
            method: 'POST',
            body: new URLSearchParams({ ...formData, description: decsription }),
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        }).then(() => handleCancel && handleCancel());
    };

    return (
        <Formik
            initialValues={{ ...INIT_VALUES, ...CONFIG.initData }}
            onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                handleSubmitForm(values);
                setSubmitting(false);
            }}
        >
            {({ values }) => (
                <Form className={styles.wrapper}>
                    <div className={styles.title}>Welcome to Wedding Spot! Send us a message and we'll respond as soon as we can.</div>
                    <div className={styles.group}>
                        <Field id='name' name='name' required={true} />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Contact Name</label>
                    </div>

                    <div className={styles.group}>
                        <Field id='email' name='email' type='email' required={true} />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Email</label>
                    </div>

                    <div className={styles.group}>
                        <Field id='guest' name='guest' required={true} />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Approximate Guest Count</label>
                    </div>

                    <div className={styles.group}>
                        <Field id='city' name='city' optional={true} />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>City</label>
                    </div>
                    <div className={styles.group}>
                        <Field id='state' name='state' />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>State</label>
                    </div>
                    <div className={styles.group}>
                        <Field id='phone' name='phone' />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Phone number</label>
                    </div>

                    <div className={styles.group}>
                        <Field id='budget' name='budget' />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Budget</label>
                    </div>

                    <div className={styles.group} style={{ marginTop: '20px' }}>
                        <Field id='desc' name='desc' rows={4} cols={40} as='textarea' />
                        <span className={styles.highlight} />
                        <span className={styles.bar} />
                        <label>Message</label>
                    </div>

                    <div className={styles.checkboxField}>
                        <Field type='checkbox' name='signup' />
                        <label>I agree to sign up for free Wedding Spot Account</label>
                    </div>

                    <div className={styles.formControls}>
                        <Button
                            className='chat-bubble--primary-cta'
                            onClick={() => {
                                handleCancel();
                            }}
                            text='Cancel'
                            appearance='secondary'
                        />
                        <Button className='chat-bubble--primary-cta' type='submit' text='Send message' appearance='primary' />
                    </div>

                    <Field hidden={true} name='orgid' type='text' />
                    <Field hidden={true} name='retURL' type='text' />
                    <Field hidden={true} name='recordType' type='text' />
                    <Field hidden={true} name='00N00000006yRRJ' type='text' />
                    <Field hidden={true} name='00N2G00000CxUzi' type='text' />
                    <Field id='description' hidden={true} name='description' />
                    <Field hidden={true} name='external' value={1} />
                </Form>
            )}
        </Formik>
    );
}
