import * as React from 'react';
import Button from '@cvent/carina/components/Button';
import { Web2CaseForm } from './Web2Case';
import NewWindow from 'react-new-window';

import styles from './LiveAgent.module.scss';

export function LiveAgent() {
    const [isVisible, setIsVisible] = React.useState(false);
    const [isPopupWindowWithHooksOpen, setIsPopupWindowWithHooksOpen] = React.useState(false);
    const liveAgentOnlineRef =  React.useRef(null);

    React.useEffect(() => {
        const lastShown = localStorage.getItem('liveagent_last_closed');
        if (!(!!lastShown && Date.now() - Date.parse(lastShown) < 3600000)) {
            setIsVisible(true);
        }
    });

    const onClose = React.useCallback(() => {
        const el = document.getElementById('liveagent_button');
        if (!el) {
            return;
        }

        setIsVisible(false);
        localStorage.setItem('liveagent_last_closed', new Date().toString());
    }, []);

    const onChatStart = React.useCallback(() => {
        // @ts-ignore
        const liveAgentOnline =  liveAgentOnlineRef && liveAgentOnlineRef.current.style.display !== 'none';
        if (window['liveagent'] && liveAgentOnline) {
            window['liveagent'].startChat(window['_la_button_id']);
        } else {
            setIsPopupWindowWithHooksOpen(true);
            setIsVisible(false);
            localStorage.setItem('liveagent_last_closed', new Date().toString());
        }
    }, []);

    return (
        <div className={styles.chatBubble} style={{ display: isVisible ? 'unset' : 'none' }}>
            <div
                id='liveagent_button'
                ref={liveAgentOnlineRef}
                style={{display: 'none'}}
            />
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    Would you like help finding <br /> venues in your area?
                </div>
                <div className={styles.content}>
                    <Button onClick={onClose} text='Maybe later' appearance='lined' />
                    <Button onClick={onChatStart} text='Message us' appearance='filled' />
                </div>
            </div>
            {isPopupWindowWithHooksOpen && (
                <NewWindow center='screen' features={{ width: 400, height: 850 }}>
                    <Web2CaseForm handleCancel={() => setIsPopupWindowWithHooksOpen(false)} />
                </NewWindow>
            )}
        </div>
    );
}
