import { Appointments } from '@weddingspot/ws-api-client';

export const TERMS_URL = '/terms-of-service/';
export type TERMS_URL = typeof TERMS_URL;

export const PRIVACY_POLICY_URL = '/privacy/';
export type PRIVACY_POLICY_URL = typeof PRIVACY_POLICY_URL;

export const CCPA_DO_NOT_SELL_URL = '/ccpa/do-not-sell/';
export type CCPA_DO_NOT_SELL_URL = typeof CCPA_DO_NOT_SELL_URL;

export const VENUE_RECOMMENDATIONS_LOADED = 'VENUE_RECOMMENDATIONS_LOADED';
export type VENUE_RECOMMENDATIONS_LOADED = typeof VENUE_RECOMMENDATIONS_LOADED;

export const VENUE_RECOMMENDATIONS_DISMISS_BANNER = 'VENUE_RECOMMENDATIONS_DISMISS_BANNER';
export type VENUE_RECOMMENDATIONS_DISMISS_BANNER = typeof VENUE_RECOMMENDATIONS_DISMISS_BANNER;

export const USER_AUTH_STATUS_UPDATED = 'USER_AUTH_STATUS_UPDATED';
export type USER_AUTH_STATUS_UPDATED = typeof USER_AUTH_STATUS_UPDATED;

export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export type USER_DATA_LOADED = typeof USER_DATA_LOADED;

export const VENUE_DATA_LOADED = 'VENUE_DATA_LOADED';
export type VENUE_DATA_LOADED = typeof VENUE_DATA_LOADED;

export const UNCONNECT_USER_EMAIL_SET = 'UNCONNECT_USER_EMAIL_SET';
export type UNCONNECT_USER_EMAIL_SET = typeof UNCONNECT_USER_EMAIL_SET;

export const IS_EXISTING_USER_SET = 'IS_EXISTING_USER_SET';
export type IS_EXISTING_USER_SET = typeof IS_EXISTING_USER_SET;

export const UNCONNECT_USER_EMAIL_CLEAR = 'UNCONNECT_USER_EMAIL_CLEAR';
export type UNCONNECT_USER_EMAIL_CLEAR = typeof UNCONNECT_USER_EMAIL_CLEAR;

export const INQUIRY_MODAL_TRANSITION_INQUIRY_FORM = 'INQUIRY_MODAL_TRANSITION_INQUIRY_FORM';
export type INQUIRY_MODAL_TRANSITION_INQUIRY_FORM = typeof INQUIRY_MODAL_TRANSITION_INQUIRY_FORM;

export const INQUIRY_MODAL_TRANSITION_PASSWORD = 'INQUIRY_MODAL_TRANSITION_PASSWORD';
export type INQUIRY_MODAL_TRANSITION_PASSWORD = typeof INQUIRY_MODAL_TRANSITION_PASSWORD;

export const INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS = 'INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS';
export type INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS = typeof INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS;

export const INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS = 'INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS';
export type INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS = typeof INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS;

export const INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM = 'INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM';
export type INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM = typeof INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM;

export const INQUIRY_MODAL_TRANSITION_ABANDONMENT = 'INQUIRY_MODAL_TRANSITION_ABANDONMENT';
export type INQUIRY_MODAL_TRANSITION_ABANDONMENT = typeof INQUIRY_MODAL_TRANSITION_ABANDONMENT;

export const INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN = 'INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN';
export type INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN = typeof INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN;

export const INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN = 'INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN';
export type INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN = typeof INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN;

export const INQUIRY_MODAL_RESET = 'INQUIRY_MODAL_RESET';
export type INQUIRY_MODAL_RESET = typeof INQUIRY_MODAL_RESET;

export const LOAD_TOUCHED_VENUES = 'LOAD_TOUCHED_VENUES';
export type LOAD_TOUCHED_VENUES = typeof LOAD_TOUCHED_VENUES;

export const INQUIRY_FORM_SUBMITTED_DATA_SET = 'INQUIRY_FORM_SUBMITTED_DATA_SET';
export type INQUIRY_FORM_SUBMITTED_DATA_SET = typeof INQUIRY_FORM_SUBMITTED_DATA_SET;

export const YOUR_PRIVACY_CHOICES = 'Your Privacy Choices';
export type YOUR_PRIVACY_CHOICES = typeof YOUR_PRIVACY_CHOICES;

export const YOUR_PRIVACY_CHOICES_URL = 'https://www.cvent.com/personal-data-requests';
export type YOUR_PRIVACY_CHOICES_URL = typeof YOUR_PRIVACY_CHOICES_URL;


export const DEFAULT_WEDDING_MESSAGE =
    'Hi,\nI found you on Wedding Spot and I would' + ' like to receive more information about having a wedding at your venue.';
export const DEFAULT_RD_MESSAGE =
    'Hi,\nI found you on Wedding Spot and I would' + ' like to receive more information about having an event at your venue.';
export const DEFAULT_WEDDING_WIDGET_MESSAGE = 'Hi,\nI would' + ' like to receive more information about having a wedding at your venue.';

export enum InquiryType {
    WEDDING = 1,
    REHEARSAL_DINNER,
}

export const REASON_NEED_PRICE = 'Need pricing information';
export const REASON_AVAILABILITY = 'Checking date availability';
export const REASON_RD_INQUIRY = 'Rehearsal dinner inquiry';
export const REASON_BS_INQUIRY = 'Bridal shower inquiry';
export const REASON_WEDDING_PACKET = 'Need wedding packet';
export const REASON_GENERAL = 'General venue question';
export const REASON_OTHER = 'Other';

export const VENUE_CONTACT_REASONS = [
    {
        label: REASON_NEED_PRICE,
        value: Appointments.ContactReasons.NEED_PRICE,
        types: [InquiryType.WEDDING, InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_AVAILABILITY,
        value: Appointments.ContactReasons.CHECK_AVAILABILITY,
        types: [InquiryType.WEDDING, InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_RD_INQUIRY,
        value: Appointments.ContactReasons.REHEARSAL_DINNER_INQUIRY,
        types: [InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_BS_INQUIRY,
        value: Appointments.ContactReasons.BRIDAL_SHOWER_INQUIRY,
        types: [InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_WEDDING_PACKET,
        value: Appointments.ContactReasons.WEDDING_PACKAGE,
        types: [InquiryType.WEDDING, InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_GENERAL,
        value: Appointments.ContactReasons.GENERAL_QUESTION,
        types: [InquiryType.WEDDING, InquiryType.REHEARSAL_DINNER],
    },
    {
        label: REASON_OTHER,
        value: Appointments.ContactReasons.OTHER,
        types: [InquiryType.WEDDING, InquiryType.REHEARSAL_DINNER],
    },
];
export type VENUE_CONTACT_REASONS = typeof VENUE_CONTACT_REASONS;

export const GO_TO_DWT_FORM_STEP = 'GO_TO_DWT_FORM_STEP';
export type GO_TO_DWT_FORM_STEP = typeof GO_TO_DWT_FORM_STEP;

export const DWT_FORM_SUCCESS = 'DWT_FORM_SUCCESS';
export type DWT_FORM_SUCCESS = typeof DWT_FORM_SUCCESS;
