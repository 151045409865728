import * as React from 'react';
import { reduxForm, Field, FormErrors, InjectedFormProps, WrappedFieldProps } from 'redux-form';

import { DWT, getCookieValue } from '@weddingspot/ws-api-client';

import { required, createValidator } from '../../utils/validators';
import LoadingSpinner from '../LoadingSpinner';
import MaterialTextInput, { MaterialTextInputProps } from '../form/MaterialTextInput';
import MaterialSelectInput from '../form/MaterialSelectInput';

import './scss/dwt-inquiry-form.module.scss';

import CaptchaCheckbox from '../form/CaptchaCheckbox';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { StorableDataKeys } from '../..';
import {v4 as uuidv4} from 'uuid';

/* Field level validators */
const requiredFieldValidator = createValidator(required, 'This field is required');
const captchaValidator = createValidator(required, 'Captcha verification failed');

/**
 * Form level validator:
 * Sync validation function for the entire form. This is where you want to add logic
 * that has
 */
const formValidator = (formData: FormData) => {
    const errors: FormErrors<FormData> = {};

    if (!formData.partner1_role) {
        errors.partner1_role = 'This field is required';
    }
    if (!formData.partner2_role) {
        errors.partner2_role = 'This field is required';
    }

    const [p1f, p1l, p2f, p2l] = [
        formData.partner1_first_name,
        formData.partner1_last_name,
        formData.partner2_first_name,
        formData.partner2_last_name,
    ];

    if (!!p1f && !!p1l && !!p2f && !!p2l && `${p1f.toLowerCase()} ${p1l.toLowerCase()}` === `${p2f.toLowerCase()} ${p2l.toLowerCase()}`) {
        errors._error = 'DestinationWeddings.com requires both partner names be unique,' + " please change one partner's name to continue.";
    }

    return errors;
};

/* Typedefs */
export interface FormData {
    partner1_role: string;
    partner1_first_name: string;
    partner1_last_name: string;

    partner2_role: string;
    partner2_first_name: string;
    partner2_last_name: string;

    country: 'United States' | 'Canada';
    captcha_widget_id?:string;
}

interface OwnProps {
    // Logo to show for attribution
    attributionLogo: string | null;

    // Handle "Back" button click
    handleBackClick: () => void;
    setCaptchaWidgetId: (captcha_widget_id?: string) => void;
}

export type InquiryFormProps = InjectedFormProps<FormData, OwnProps> & OwnProps;

/* Constants */

/**
 * Form used to submit inquiries
 */
let InquiryForm: React.SFC<InquiryFormProps> = (props) => {
    let _props: InquiryFormProps & { children?: React.ReactNode } = { ...props };

    const { handleSubmit, error, submitting, pristine } = _props;
    const [recaptchaRendered, setRecaptchaRendered] = React.useState(false);
    const [uuid, setUuid] = React.useState<string>('');

    React.useEffect(() => {
        if(!recaptchaRendered){
            setUuid(uuidv4().replace(/-/gi, ''));
            setRecaptchaRendered(true);
        }
    }, []);

    React.useEffect( () => {
        if (uuid != '') {
            const grecaptcha = document.createElement('script');
            grecaptcha.type = 'text/javascript';
            grecaptcha.async = true;
            grecaptcha.defer = true;
            grecaptcha.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallbackDWTInquiryForm_${uuid}&render=explicit`;
            document.head.appendChild(grecaptcha);

            const callbackMethod = document.createElement('script');
            callbackMethod.type = 'text/javascript';
            callbackMethod.text = `
            var recaptchaCallbackDWTInquiryForm_${uuid} = function () {
                if(document.getElementById('dwt-inquiry-form-recaptcha-${uuid}')) {
                    let recaptcha_widget_id_dwt_inquiry_form = grecaptcha.render('dwt-inquiry-form-recaptcha-${uuid}',{
                        'sitekey':'${RECAPTCHA_SITE_KEY}', 
                        'callback': function (response) {
                            document.getElementById('dwt-inquiry-form-hidden-input-${uuid}').click();
                        },
                        'expired-callback': function (response) {
                            document.getElementById('dwt-inquiry-form-hidden-input-${uuid}').click(); 
                        }
                    })
                    if(document.getElementById('dwt-inquiry-form-hidden-widget-id-${uuid}')) {
                        document.getElementById('dwt-inquiry-form-hidden-widget-id-${uuid}').dataset.widget_id = recaptcha_widget_id_dwt_inquiry_form;
                    }
                }
            }
            `;
            document.head.appendChild(callbackMethod);
            setRecaptchaRendered(true);
        }
    },[uuid]);

    return (
        <form className={'InquiryForm--form'} onSubmit={handleSubmit}>
            <div className='InquiryForm--fields Inquiry--fields__step-2'>
                <div className='InquiryForm--flex-container'>
                    <div className='InquiryForm--row InquiryForm--radio-row'>
                        <div>
                            <span className='InquiryForm--radio-row--text'>Partner 1:</span>
                            <div className='nx-radio nx-radio--small'>
                                <label className='InquiryForm--inline-radio-lbl'>
                                    <Field name='partner1_role' component='input' type='radio' value={`${DWT.DWTRoles.BRIDE}`} />
                                    Bride
                                </label>
                            </div>

                            <div className='nx-radio nx-radio--small'>
                                <label className='InquiryForm--inline-radio-lbl'>
                                    <Field
                                        name='partner1_role'
                                        component='input'
                                        type='radio'
                                        validate={[requiredFieldValidator]}
                                        value={`${DWT.DWTRoles.GROOM}`}
                                    />
                                    Groom
                                </label>
                            </div>
                            <Field
                                name='partner1_role'
                                component={(props: MaterialTextInputProps) => {
                                    if (props.meta.error && props.meta.touched) {
                                        return <div className='MaterialTextInput--error'>{props.meta.error}</div>;
                                    }

                                    return null;
                                }}
                            />
                        </div>
                    </div>

                    <div className='InquiryForm--row'>
                        <div className='InquiryForm--col InquiryForm--col--left'>
                            <div className='InquiryForm--col-content'>
                                <Field
                                    name='partner1_first_name'
                                    label='First name'
                                    validate={[requiredFieldValidator]}
                                    component={MaterialTextInput}
                                    placeholder='First name'
                                    ariaLabel='First name'
                                />
                            </div>
                        </div>
                        <div className='InquiryForm--col InquiryForm--col--right'>
                            <div className='InquiryForm--col-content'>
                                <Field
                                    name='partner1_last_name'
                                    label='Last name'
                                    validate={[requiredFieldValidator]}
                                    component={MaterialTextInput}
                                    placeholder='Last name'
                                    ariaLabel='Last name'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='InquiryForm--row InquiryForm--radio-row'>
                        <div className='InquiryForm--partner2-role-container'>
                            <span className='InquiryForm--radio-row--text'>Partner 2:</span>
                            <div className='nx-radio nx-radio--small'>
                                <label className='InquiryForm--inline-radio-lbl'>
                                    <Field name='partner2_role' component='input' type='radio' value={`${DWT.DWTRoles.BRIDE}`} />
                                    Bride
                                </label>
                            </div>

                            <div className='nx-radio nx-radio--small'>
                                <label className='InquiryForm--inline-radio-lbl'>
                                    <Field
                                        name='partner2_role'
                                        component='input'
                                        type='radio'
                                        validate={[requiredFieldValidator]}
                                        value={`${DWT.DWTRoles.GROOM}`}
                                    />
                                    Groom
                                </label>
                            </div>
                            <Field
                                name='partner2_role'
                                component={(props: MaterialTextInputProps) => {
                                    if (props.meta.error && props.meta.touched) {
                                        return <div className='MaterialTextInput--error'>{props.meta.error}</div>;
                                    }

                                    return null;
                                }}
                            />
                        </div>
                    </div>

                    <div className='InquiryForm--row'>
                        <div className='InquiryForm--col InquiryForm--col--left'>
                            <div className='InquiryForm--col-content'>
                                <Field
                                    name='partner2_first_name'
                                    label='First name'
                                    validate={[requiredFieldValidator]}
                                    component={MaterialTextInput}
                                    placeholder='First name'
                                    ariaLabel='First name'
                                />
                            </div>
                        </div>
                        <div className='InquiryForm--col InquiryForm--col--right'>
                            <div className='InquiryForm--col-content'>
                                <Field
                                    name='partner2_last_name'
                                    label='Last name'
                                    validate={[requiredFieldValidator]}
                                    component={MaterialTextInput}
                                    placeholder='Last name'
                                    ariaLabel='Last name'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='InquiryForm--row InquiryForm--country-row'>
                        <Field
                            name='country'
                            label='Country of residence'
                            placeholder='Country of residence'
                            validate={[requiredFieldValidator]}
                            component={MaterialSelectInput}
                            choices={[
                                [`${DWT.CountryOfResidence.US}`, 'United States'],
                                [`${DWT.CountryOfResidence.CANADA}`, 'Canada'],
                            ]}
                            {...{ disableSearch: true }}
                            ariaLabel='Country of residence'
                        />
                    </div>
                </div>
            </div>

            {error && <div className='InquiryForm--error'>{error}</div>}


            {/* Dont show captcha to the logged-in user OR to those non-logged-in user, who has already */}
            {/* verified CAPTCHA once in the current session */}
            <div
                id={'dwt-inquiry-form-recaptcha-'+uuid}
                hidden={(getCookieValue(StorableDataKeys.RECAPTCHA_VALIDATION_SUCCESSFUL) === '1')}
            />

            {/* Add hidden checkbox (to store the value whether CAPTCHA is clicked or not) ONLY for if */}
            {/* the user is non-logged in and has not already verified the CAPTCHA in the current session */}
            {(getCookieValue(StorableDataKeys.RECAPTCHA_VALIDATION_SUCCESSFUL) !== '1') &&
                <>
                    <Field
                        name='captcha'
                        id={'dwt-inquiry-form-hidden-input-'+uuid}
                        component={CaptchaCheckbox}
                        validate={[captchaValidator]}
                        ariaLabel='Captcha'
                        onChange={() => {
                            props.setCaptchaWidgetId(document.getElementById('dwt-inquiry-form-hidden-widget-id-'+uuid)?.dataset.widget_id);
                        }}
                    />
                    <Field
                        name='captcha_widget_id'
                        id={'dwt-inquiry-form-hidden-widget-id-'+uuid}
                        component='input'
                        label='Captcha Widget ID'
                        ariaLabel='Captcha Widget ID'
                        hidden={true}
                    />
                </>
            }

            <div className={'InquiryForm--cta-container'}>
                {!!props.attributionLogo && (
                    <div className='InquiryForm--attribution-logo nx-hide-for-small-only'>
                        {'POWERED BY '}
                        <img src={props.attributionLogo} title={'Inquiry attribution source'} />
                    </div>
                )}

                <div className='InquiryForm--cta-spinner-container'>
                    <button className='nx-button' disabled={submitting} onClick={props.handleBackClick} type='button'>
                        Back
                    </button>
                    <button className='nx-button nx-button--primary' disabled={pristine || submitting} type='submit'>
                        Send Inquiry
                    </button>
                    {submitting && <LoadingSpinner />}
                </div>
            </div>

            {!!props.attributionLogo && (
                <div className='InquiryForm--attribution-logo nx-show-for-small-only'>
                    {'POWERED BY '}
                    <img src={props.attributionLogo} title={'Inquiry attribution source'} />
                </div>
            )}
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    form: 'DWTInquiryForm',
    validate: formValidator,
    touchOnBlur: false, // Prevents validation on blur
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(InquiryForm);
