/**
 * DWT Inquiry Widget
 */

import * as React from 'react';

import DWTInquiryFormContainer from './DWTInquiryFormContainer';

interface Props {
    venueId: number;
    onGoToStep2?: () => void;
    handleInquirySubmitSuccess?: (isQuoteRequest: boolean) => void;
}

export default class DWTInquiryFormWidget extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleInquirySuccess = this.handleInquirySuccess.bind(this);
        this.handleQuoteSuccess = this.handleQuoteSuccess.bind(this);
    }

    render() {
        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--inquiry-form'>
                    <DWTInquiryFormContainer
                        venueId={this.props.venueId}
                        onGoToStep2={this.props.onGoToStep2}
                        handleSubmitInquirySuccess={this.handleInquirySuccess}
                        handleSubmitQuoteSuccess={this.handleQuoteSuccess}
                    />
                </div>
            </div>
        );
    }

    private handleInquirySuccess() {
        !!this.props.handleInquirySubmitSuccess && this.props.handleInquirySubmitSuccess(false);
    }

    private handleQuoteSuccess() {
        !!this.props.handleInquirySubmitSuccess && this.props.handleInquirySubmitSuccess(true);
    }
}
