import * as React from 'react';
import BasicOutreachPushFormContainer, { BasicOutreachPushFormContainerProps } from './BasicOutreachPushFormContainer';
import { SubmissionError } from 'redux-form';
import { VendorsApi } from '@weddingspot/ws-api-client';
import { format } from 'date-fns';

const DEFAULT_INQUIRY_MESSAGE =
    'Hi,I found you on Wedding Spot and I would like to receive more information about having a wedding at your venue.';

const OutreachPushFormContainer: React.SFC<BasicOutreachPushFormContainerProps> = (props) => {
    return (
        <BasicOutreachPushFormContainer
            {...props}
            form='OutreachPushForm'
            submitHandler={(venueIds, checkedPos) => {
                return new Promise<void>((resolve, reject) => {
                    let submitData = {
                        vendor_ids: venueIds,
                        message: DEFAULT_INQUIRY_MESSAGE,
                    };
                    let apiMethod;

                    if (!props.userToken) {
                        submitData = {
                            ...submitData,
                            ...props.inquiryFormData,

                            // before WS-940 preferred_wedding_date was ignored on dw inquiry
                            // modified in this place, because of dw state/form specification
                            // @ts-ignore
                            preferred_wedding_date: props.inquiryFormData.preferred_wedding_date
                                ? // @ts-ignore
                                  format(new Date(props.inquiryFormData.preferred_wedding_date), 'yyyy-MM-dd')
                                : undefined,
                        };
                        VendorsApi.sendMassInquiries(submitData)
                            .then(() => {
                                props.handleExtraOutreachSuccess(checkedPos);
                                resolve();
                            })
                            .catch(() => {
                                reject(
                                    new SubmissionError({
                                        _error: 'Inquiry submission failed. Please try again later',
                                    })
                                );
                            });
                    } else {
                        VendorsApi.sendMassInquiriesViaToken({
                            ...submitData,
                            token: props.userToken,
                        })
                            .then(() => {
                                props.handleExtraOutreachSuccess(checkedPos);
                                resolve();
                            })
                            .catch(() => {
                                reject(
                                    new SubmissionError({
                                        _error: 'Inquiry submission failed. Please try again later',
                                    })
                                );
                            });
                    }
                });
            }}
        />
    );
};

export default OutreachPushFormContainer;
