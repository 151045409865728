import * as React from 'react';

import * as types from './types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Venues, VendorsApi } from '@weddingspot/ws-api-client';

import { ResponsiveModalContainer } from '../responsive-modal';
import { InquiryWidget } from './InquiryWidget';
import InquiryFormWidget from './InquiryFormWidget';
import {
    InquiryModalAction,
    createResetModalAction,
    createTransitionPasswordAction,
    createTransitionRecommendationsAction,
    createTransitionAbandonmentAction,
    createTransitionInquiryFormAction,
    createTransitionSuccessScreenAction,
    createTransitionHotelRecommendationsAction,
    createTransitionSeeNearbyHotelsScreenAction,
    createTransitionHotelRecommendationsFormAction,
} from './actions';
import { loadHotelRecommendationsAction, loadVenueRecommendationsAction, recommendationsRequested } from '../recommendations';

import './scss/inquiry-modal.module.scss';
import { InquiryType } from './constants';

import { StorableDataKeys } from '../../utils/storage';
import { submit } from 'redux-form';
import { getPathname, getReferrer } from '../../utils/url';
import LocalStorage from '../../utils/storage';

interface OwnProps {
    // Props passed on instantiation
    showModal: boolean;
    shouldShowAbandonment?: () => boolean;
    handleModalClosed?: () => void;
    userIsAuthed?: boolean;
    isWidgetFlow?: boolean;
    hideReasonForOutreachField?: boolean; // whether or not to show reason field
    hidePhoneNumberField?: boolean; // whether or not to show phone number field
    testGuestRoomsIntentCta?: 'A' | 'B';
    intentCTAonPostOutreachStep1?: 'A' | 'B';
    intentCTAStepRemoved?: 'A' | 'B';
    showPhoneAndHideReason: boolean;
    showGuestRoomsRecommendations?: boolean;
    showWsRfpForm?: boolean;

    // Redux-connected props
    resetModal: () => void;

    // pass though InquiryFormProps
    venueId: number;
    inquiryType?: InquiryType;
    handleInquirySubmitSuccess?: (isQuoteRequest: boolean) => void;
    isEmailExists?: () => void;

    // pass through InquiryWidgetProps (minus render form function)
    venueName: string;
    isQuoteRequest: boolean;
    isDestination: boolean;
    userIsLoggedIn: boolean;
    userEmail: string;
    isExistingUser: boolean;

    handleNavigatedToConnectAccount?: () => void;
    handleNavigatedToPostOutreachScreen?: () => void;
    handleConnectAccountSuccess?: () => void;
    handleRecommendationsClick?: (position: number, recommendations: Venues.VenueRecommendation[]) => void;
    handleCloseClicked?: (screen?: string) => void;
    handleAbandonmentShown?: () => void;
    handleAbandonmentCta?: () => void;
    handleAbandonmentDismiss?: () => void;

    navigateToInquiryForm: () => void;
    navigateToPasswordForm: () => void;
    loadRecommendations: (venueId: number, inquiryType?: InquiryType) => void;
    loadHotelRecommendations: (venueId: number) => void;
    navigateToRecommendations: () => void;
    navigateToHotelRecommendations: () => void;
    navigateToHotelForm: () => void;
    navigateToAbandonment: () => void;
    navigateToSuccessScreen: () => void;
    navigateToSeeNearbyHotelsScreen: () => void;
    // modalOutreachPush?: false | null;
    submitOutreachPushForm: () => void;
    closestSRPURL?: string;
    pushOutreachNoThanksClicked?: () => void;
    pushOutreachSuccessBrowseMoreClicked?: () => void;
    pushOutreachInquireClicked?: (position: number[]) => void;
    pushIntentCTAonPostOutreachStep1?: () => void;
    handleNavigatedToSeeNearbyHotels?: () => void;
    handleNavigatedToHotels?: (count: number) => void;
    handleRejectSeeHotels?: () => void;
    handleRejectHotelsRecommended?: () => void;
    handleHotelsRecommendedRFP?: () => void;
    handleHotelsRecommendedForm?: (source: string, count: number) => void;
    handleRoomCountSubmit?: (params: object) => void;
    handleClickedSpecifyGuestRooms?: () => void;
}

export type InquiryModalProps = OwnProps & types.InquiryModalState;

export class InquiryModalContent<T extends InquiryModalProps> extends React.Component<T> {
    constructor(props: T) {
        super(props);
        this.navigateToClosestSRP = this.navigateToClosestSRP.bind(this);
        this.handleAbandonmentDismiss = this.handleAbandonmentDismiss.bind(this);
    }

    componentDidUpdate(prevProps: T) {
        if (this.props.curStep !== prevProps.curStep) {
            if (this.props.curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS) {
                let venueIds = prevProps.recommendations.map((rec) => rec.id);
                VendorsApi.logVenueRecomendationsImpression({
                    venue_ids: venueIds,
                    venue_recommended_for_id: this.props.venueId,
                    recommended_from: Venues.InventoryType.INV_SUBTYPE_REC_APPT_CONFIRMATION,
                    metrics_metatype:
                        this.props.inquiryType === InquiryType.REHEARSAL_DINNER
                            ? Venues.VenueMetricsMetaType.RD_METRIC
                            : Venues.VenueMetricsMetaType.WEDDING_METRIC,
                });
            }
        }
    }

    navigateToClosestSRP() {
        const referrerPathname = compose(getPathname, getReferrer)();
        const srpRegex = new RegExp('^/([a-z]|-)*wedding-venues/', 'i');
        window.location.href = (srpRegex.test(referrerPathname) && referrerPathname) || this.props.closestSRPURL! || '/wedding-venues/';
    }

    closeModal = () => {
        if (this.props.handleCloseClicked) {
            let screen;

            switch (this.props.curStep) {
                case types.InquiryModalStage.VENUE_RECOMMENDATIONS:
                    screen = 'Recommendations';
                    break;
                case types.InquiryModalStage.SUCCESS:
                    screen = 'Success';
                    break;
                case types.InquiryModalStage.PASSWORD_FORM:
                    screen = 'Signup';
                    break;
                default:
                    break;
            }

            this.props.handleCloseClicked(screen);

            if (
                (this.props.curStep === types.InquiryModalStage.SUCCESS ||
                    this.props.curStep === types.InquiryModalStage.SEE_NEARBY_HOTELS ||
                    this.props.curStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS ||
                    this.props.curStep === types.InquiryModalStage.PASSWORD_FORM) &&
                !this.props.isWidgetFlow &&
                this.props.prevStep !== types.InquiryModalStage.HOTEL_RECOMMENDATIONS_RFP_FORM
            ) {
                this.navigateToClosestSRP();
            }
        }

        if (
            this.props.curStep === types.InquiryModalStage.INQUIRY_FORM &&
            !!this.props.shouldShowAbandonment &&
            this.props.shouldShowAbandonment()
        ) {
            this.props.navigateToAbandonment();
            !!this.props.handleAbandonmentShown && this.props.handleAbandonmentShown();
            return;
        }

        if (!!this.props.handleModalClosed) {
            this.props.handleModalClosed();
            if (this.props.curStep === types.InquiryModalStage.ABANDONMENT) {
                !!this.props.handleAbandonmentDismiss && this.props.handleAbandonmentDismiss();
            }
        }

        if (
            this.props.curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS ||
            this.props.curStep === types.InquiryModalStage.ABANDONMENT
        ) {
            this.props.resetModal();
        }
    };

    getHeaderContent() {
        const { curStep, userEmail } = this.props;

        if (curStep === types.InquiryModalStage.ABANDONMENT) {
            return "You're so close!";
        } else if (curStep === types.InquiryModalStage.PASSWORD_FORM) {
            if (!userEmail) {
                return 'Oops!';
            }
            return 'Save Your Information';
        } else if (curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS) {
            return this.props.intentCTAonPostOutreachStep1 === 'B'
                ? 'Explore our best hotel room blocks recommendations'
                : 'Explore recommendations, find your dream venue';
        } else if (curStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS) {
            return 'Inquiry Sent Successfully!';
        } else if (curStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS_RFP_FORM) {
            return 'Request a Proposal';
        } else if (curStep === types.InquiryModalStage.SUCCESS) {
            return 'Success';
        } else if (curStep === types.InquiryModalStage.SEE_NEARBY_HOTELS) {
            return this.props.massInquiryFormSuccess ? 'Success' : 'Hotel room blocks';
        } else {
            if (this.props.isDestination) {
                return `Contact ${this.props.venueName} via DestinationWeddings.com`;
            }
            return `Contact ${this.props.venueName}`;
        }
    }

    renderModalContent() {
        return (
            <InquiryWidget
                renderInquiryForm={(props) => {
                    return <InquiryFormWidget {...props} />;
                }}
                {...this.props}
                handleClickModalClose={this.closeModal}
                navigateToClosestSRP={this.navigateToClosestSRP}
                handleAbandonmentDismiss={this.handleAbandonmentDismiss}
                submitOutreachPushForm={this.props.submitOutreachPushForm}
                pushOutreachNoThanksClicked={this.props.pushOutreachNoThanksClicked}
                pushOutreachSuccessBrowseMoreClicked={this.props.pushOutreachSuccessBrowseMoreClicked}
                pushIntentCTAonPostOutreachStep1={this.props.pushIntentCTAonPostOutreachStep1}
                pushOutreachInquireClicked={this.props.pushOutreachInquireClicked}
                testGuestRoomsIntentCta={this.props.testGuestRoomsIntentCta}
                intentCTAonPostOutreachStep1={this.props.intentCTAonPostOutreachStep1}
                isEmailExists={this.props.isEmailExists}
                showGuestRoomsRecommendations={this.props.showGuestRoomsRecommendations}
                showWsRfpForm={this.props.showWsRfpForm}
                handleRoomCountSubmit={this.props.handleRoomCountSubmit}
                handleClickedSpecifyGuestRooms={this.props.handleClickedSpecifyGuestRooms}
                intentCTAStepRemoved={this.props.intentCTAStepRemoved}
            />
        );
    }

    handleAbandonmentDismiss() {
        this.closeModal();
        !!this.props.handleAbandonmentDismiss && this.props.handleAbandonmentDismiss();
    }

    render() {
        const isMoreRecommendations = this.props.curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS && !this.props.isWidgetFlow;
        return (
            <div className='InquiryModal' data-step={this.props.curStep}>
                <ResponsiveModalContainer
                    show={this.props.showModal}
                    headerContent={this.getHeaderContent()}
                    handleClickModalBackground={this.closeModal}
                    handleClickModalClose={this.closeModal}
                    className={isMoreRecommendations ? 'is-more-recommendations' : ''}
                >
                    <div className='InquiryModalContent'>{this.renderModalContent()}</div>
                </ResponsiveModalContainer>
            </div>
        );
    }
}

const mapStateToProps = (state: types.InquiryModalCombinedState) => {
    const modalState = state.inquiryModalState;
    const recState = state.venueRecommendations;
    const inquiryFormContainer = state.inquiryFormContainerState;
    return {
        ...modalState,
        userIsLoggedIn: state.authState.userIsLoggedIn,
        userEmail: state.authState.unconnectedUserEmail,
        isExistingUser: state.authState.isExistingUser,
        recommendations: recState.recommendations,
        recommendationsLoaded: recState.recommendationsLoaded,
        hotels: recState.hotels,
        hotelsLoaded: recState.hotelsLoaded,
        venueName: inquiryFormContainer.venueName,
        isQuoteRequest: inquiryFormContainer.isQuoteRequest,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<types.InquiryModalCombinedState, undefined, InquiryModalAction>) => ({
    navigateToInquiryForm: () => {
        dispatch(createTransitionInquiryFormAction());
    },
    navigateToPasswordForm: () => {
        dispatch(createTransitionPasswordAction());
    },
    loadRecommendations: (venueId: number, inquiryType?: InquiryType) => {
        // TODO: Migrate this to session store once we have API for it
        let visitedVenues = LocalStorage.getItem(StorableDataKeys.VISITED_VENUES, []);

        dispatch(recommendationsRequested(inquiryType || InquiryType.WEDDING));
        dispatch(loadVenueRecommendationsAction(venueId, visitedVenues, 7, inquiryType));
    },
    loadHotelRecommendations: (venueId: number) => {
        dispatch(loadHotelRecommendationsAction(venueId));
    },
    navigateToRecommendations: () => {
        dispatch(createTransitionRecommendationsAction());
    },
    navigateToHotelRecommendations: () => {
        dispatch(createTransitionHotelRecommendationsAction());
    },
    navigateToHotelForm: () => {
        dispatch(createTransitionHotelRecommendationsFormAction());
    },
    navigateToAbandonment: () => {
        dispatch(createTransitionAbandonmentAction());
    },
    navigateToSuccessScreen: () => {
        dispatch(createTransitionSuccessScreenAction());
    },
    navigateToSeeNearbyHotelsScreen: () => {
        dispatch(createTransitionSeeNearbyHotelsScreenAction());
    },
    resetModal: () => {
        dispatch(createResetModalAction());
    },
    submitOutreachPushForm: () => {
        dispatch(submit('OutreachPushForm'));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(InquiryModalContent);
