import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import * as types from './types';
import { InquiryModalContent, InquiryModalProps } from './InquiryModal';
import { InquiryWidget } from './InquiryWidget';
import DWTInquiryFormWidget from './DWTInquiryFormWidget';
import {
    InquiryModalAction,
    createResetModalAction,
    createTransitionPasswordAction,
    createTransitionRecommendationsAction,
    createTransitionAbandonmentAction,
    createTransitionInquiryFormAction,
    createTransitionSuccessScreenAction,
    createTransitionHotelRecommendationsAction,
    createTransitionSeeNearbyHotelsScreenAction,
    createTransitionHotelRecommendationsFormAction,
} from './actions';
import { loadHotelRecommendationsAction, loadVenueRecommendationsAction } from '../recommendations/actions';

import './scss/inquiry-modal.module.scss';

import { StorableDataKeys } from '../../utils/storage';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { getPathname, getReferrer } from '../../utils/url';
import LocalStorage from '../../utils/storage';

interface OwnProps extends InquiryModalProps {
    onGoToStep2?: () => void;
}

export class DWTInquiryModal extends InquiryModalContent<OwnProps> {
    navigateToClosestSRP() {
        const referrerPathname = compose(getPathname, getReferrer)();
        const srpRegex = new RegExp('^/([a-z]|-)*wedding-venues/', 'i');
        window.location.href = (srpRegex.test(referrerPathname) && referrerPathname) || this.props.closestSRPURL! || '/wedding-venues/';
    }

    renderModalContent() {
        return (
            <InquiryWidget
                {...this.props}
                handleClickModalClose={() => void 0} // A dirty fix for typescript.
                isDestination={true}
                navigateToClosestSRP={this.navigateToClosestSRP}
                renderInquiryForm={(props) => {
                    return <DWTInquiryFormWidget {...props} />;
                }}
                handleAbandonmentDismiss={this.handleAbandonmentDismiss}
            />
        );
    }
}

const mapStateToProps = (state: types.InquiryModalCombinedState) => {
    const modalState = state.inquiryModalState;
    const recState = state.venueRecommendations;
    const inquiryFormContainer = state.inquiryFormContainerState;
    return {
        ...modalState,
        userIsLoggedIn: state.authState.userIsLoggedIn,
        userEmail: state.authState.unconnectedUserEmail,
        isExistingUser: state.authState.isExistingUser,
        recommendations: recState.recommendations,
        recommendationsLoaded: recState.recommendationsLoaded,
        hotels: recState.hotels,
        hotelsLoaded: recState.hotelsLoaded,
        venueName: inquiryFormContainer.venueName,
        isQuoteRequest: inquiryFormContainer.isQuoteRequest,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<types.InquiryModalCombinedState, undefined, InquiryModalAction>) => ({
    navigateToInquiryForm: () => {
        dispatch(createTransitionInquiryFormAction());
    },
    navigateToPasswordForm: () => {
        dispatch(createTransitionPasswordAction());
    },
    loadRecommendations: (venueId: number) => {
        // TODO: Migrate this to session store once we have API for it
        let visitedVenues = LocalStorage.getItem(StorableDataKeys.VISITED_VENUES, []);

        dispatch(loadVenueRecommendationsAction(venueId, visitedVenues, 7));
    },
    loadHotelRecommendations: (venueId: number) => {
        dispatch(loadHotelRecommendationsAction(venueId));
    },
    navigateToRecommendations: () => {
        dispatch(createTransitionRecommendationsAction());
    },
    navigateToHotelRecommendations: () => {
        dispatch(createTransitionHotelRecommendationsAction());
    },
    navigateToHotelForm: () => {
        dispatch(createTransitionHotelRecommendationsFormAction());
    },
    navigateToAbandonment: () => {
        dispatch(createTransitionAbandonmentAction());
    },
    navigateToSuccessScreen: () => {
        dispatch(createTransitionSuccessScreenAction());
    },
    navigateToSeeNearbyHotelsScreen: () => {
        dispatch(createTransitionSeeNearbyHotelsScreenAction());
    },
    resetModal: () => {
        dispatch(createResetModalAction());
    },
    submitOutreachPushForm: () => {
        dispatch(submit('OutreachPushForm'));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DWTInquiryModal);
