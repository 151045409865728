import * as React from 'react';
import { connect } from 'react-redux';
import { change, reset, FormState, InjectedFormProps, SubmissionError } from 'redux-form';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Auth, DwtApi } from '@weddingspot/ws-api-client';

import * as types from './types';
import {
    AppointmentsActions,
    createSetUnconnectedUserEmailAction,
    dispatchDWTFormStep1,
    dispatchDWTFormStep2,
    dispatchDWTFormSuccess,
    loadUserAuthStatusAndUserData,
    loadUserDataOnly,
    loadVenueData,
    setInquiryFormSubmittedData,
} from './actions';
import InquiryForm, { CombinedFormData } from './DWTInquiryForm';
import { format } from 'date-fns';
import { LocalStorage, StorableDataKeys } from '../..';

/* Typedefs */
export interface OwnProps {
    // External
    venueId: number;
    onGoToStep2?: () => void;

    // Reducer props
    authState: types.AuthState;
    formValues: CombinedFormData;
    datesFlexible: boolean;
    venueName: string;
    isQuoteRequest: boolean;
    requireGuestCount: boolean;
    availability: string[][] | null;
    blackoutDays: Array<number> | null;
    attributionLogo: string | null;
    curStep: types.DWTInquiryFormStep;
    showStep2: boolean;
    userDataLoaded: boolean;
    goToStep1: () => void;
    goToStep2: (partner1Role?: string, partner1FirstName?: string, partner1LastName?: string) => void;
    formSubmitSuccess: () => void;

    // actions
    loadAuthStatus: (disabledMoments: Date[], datesFlexible: boolean) => void;
    loadUserData: (disabledMoments: Date[], datesFlexible: boolean) => void;
    updateFormUserData: (userData: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => void;
    updateForm: (field: string, value: any) => void;
    loadVenueData: (venueId: number) => void;

    // Callbacks
    handleSubmitInquirySuccess?: () => void;
    handleSubmitQuoteSuccess?: () => void;
}

type InquiryFormContainerProps = OwnProps & Pick<InjectedFormProps, 'initialValues'>;

/**
 * Submit handler
 * returns a promise that resolves on successful request and
 * rejects on submission error from the API
 */
const submitHandlerGenerator = (props: InquiryFormContainerProps) => {
    return (data: CombinedFormData, dispatch: Dispatch<any>) => {
        const {captcha_widget_id, ...submit_data} = data;
        const submitData = {
            ...submit_data,
            venue_id: props.venueId,
            role: parseInt(data.role, 10),
            partner1_role: parseInt(data.partner1_role, 10),
            partner2_role: parseInt(data.partner2_role, 10),
            country: parseInt(data.country, 10),
        };

        if (submitData.preferred_wedding_date) {
            submitData.preferred_wedding_date = format(new Date(submitData.preferred_wedding_date), 'yyyy-MM-dd');
        } else {
            submitData.preferred_wedding_date = undefined;
        }
        if (props.showStep2) {
            // @ts-ignore
            submitData.grecaptcha_response = grecaptcha.getResponse(captcha_widget_id);
        }


        const promise = new Promise<void>((resolve, reject) => {
            DwtApi.sendQuoteRequest(submitData)
                .then(() => {
                    if (data.email) {
                        dispatch(createSetUnconnectedUserEmailAction(data.email));
                    }
                    dispatch(props.formSubmitSuccess);
                    dispatch(setInquiryFormSubmittedData(data));
                    dispatch(reset('DWTInquiryForm'));

                    if (props.isQuoteRequest) {
                        if (!!props.handleSubmitQuoteSuccess) {
                            props.handleSubmitQuoteSuccess();
                        }
                    } else {
                        if (!!props.handleSubmitInquirySuccess) {
                            props.handleSubmitInquirySuccess();
                        }
                    }

                    resolve();
                })
                .catch((response: any) => {
                    // TODO: move this into the API client
                    if (response && response.detail && response.detail.content && response.detail.content.errors) {
                        const errors = response.detail.content.errors;
                        reject(
                            new SubmissionError({
                                ...errors,
                                _error: 'Inquiry submission failed. Please review the errors and try again',
                            })
                        );
                    } else {
                        // Unhandled uknown error (i.e. network error, timeout and etc.)
                        reject(
                            new SubmissionError({
                                _error: 'Inquiry submission failed. Please try again later',
                            })
                        );
                    }
                });
        });

        return promise;
    };
};

const updateUserData = (dispatch: Dispatch<AppointmentsActions>, data: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => {
    if (data !== undefined) {
        let phone_number = data.phone_number;
        if (phone_number) {
            // just removing ext manually
            // because mask is not applying for initial data
            if (phone_number.indexOf(' ext') > -1) {
                phone_number = phone_number.slice(0, phone_number.indexOf(' ext'));
            }
            dispatch(change('DWTInquiryForm', 'phone_number', phone_number));
        }

        if (data.dates_are_flexible) {
            if (!datesFlexible) {
                dispatch(change('DWTInquiryForm', 'dates_flexible', true));
            }
        } else {
            if (data.preferred_event_date_1) {
                const preferred = new Date(data.preferred_event_date_1);

                if (preferred.getTime() > new Date().getTime() && !new Set(disabledMoments).has(preferred) && !datesFlexible) {
                    dispatch(change('DWTInquiryForm', 'preferred_wedding_date', format(preferred, 'yyyy-MM-dd')));
                }
            }
        }

        // Set DWT fields
        if (data.role) {
            dispatch(change('DWTInquiryForm', 'role', `${data.role}`));
        }
        if (data.country) {
            dispatch(change('DWTInquiryForm', 'country', `${data.country}`));
        }
        if (data.partner1_role) {
            dispatch(change('DWTInquiryForm', 'partner1_role', `${data.partner1_role}`));
        }
        if (data.partner1_first_name) {
            dispatch(change('DWTInquiryForm', 'partner1_first_name', `${data.partner1_first_name}`));
        }
        if (data.partner1_last_name) {
            dispatch(change('DWTInquiryForm', 'partner1_last_name', `${data.partner1_last_name}`));
        }
        if (data.partner2_role) {
            dispatch(change('DWTInquiryForm', 'partner2_role', `${data.partner2_role}`));
        }
        if (data.partner2_first_name) {
            dispatch(change('DWTInquiryForm', 'partner2_first_name', `${data.partner2_first_name}`));
        }
        if (data.partner2_last_name) {
            dispatch(change('DWTInquiryForm', 'partner2_last_name', `${data.partner2_last_name}`));
        }
    }
};

class DWTInquiryFormContainer extends React.Component<InquiryFormContainerProps> {
    disabledMoments: Date[];

    constructor(props: InquiryFormContainerProps) {
        super(props);

        this.onGoToStep2 = this.onGoToStep2.bind(this);

        this.disabledMoments = [];
        if (!!props.availability) {
            this.disabledMoments = props.availability.filter((d) => d[1] === 'Reserved').map((d) => new Date(d[0]));
        }
    }

    componentDidMount() {
        const loggedIn = this.props.authState.userIsLoggedIn;
        this.props.loadVenueData(this.props.venueId);
        if (loggedIn === undefined) {
            this.props.loadAuthStatus(this.disabledMoments, this.props.datesFlexible);
        } else if (loggedIn && this.props.authState.userData === undefined) {
            this.props.loadUserData(this.disabledMoments, this.props.datesFlexible);
        } else if (loggedIn && !!this.props.authState.userData) {
            this.props.updateFormUserData(this.props.authState.userData, this.disabledMoments, this.props.datesFlexible);
        }
    }

    render() {
        return (
            <div>
                <InquiryForm
                    initialValues={this.props.initialValues}
                    showStep2={this.props.showStep2}
                    userDataLoaded={this.props.userDataLoaded}
                    showSignupFields={!this.props.authState.userIsLoggedIn || false}
                    disabledDates={this.disabledMoments}
                    submitHandler={submitHandlerGenerator(this.props)}
                    requireGuestCount={this.props.requireGuestCount}
                    attributionLogo={this.props.attributionLogo}
                    clearDatesFlexible={() => {
                        this.props.updateForm('dates_flexible', false);
                    }}
                    clearPreferredDates={() => {
                        this.props.updateForm('preferred_wedding_date', '');
                    }}
                    setCaptchaWidgetId={(captcha_widget_id?: string) =>{
                        this.props.updateForm('captcha_widget_id', captcha_widget_id || '');
                    }}
                    curStep={this.props.curStep}
                    handleGoToStep1={this.props.goToStep1}
                    handleGoToStep2={this.onGoToStep2}
                />
            </div>
        );
    }

    private onGoToStep2(partner1Role?: string, partner1FirstName?: string, partner1LastName?: string) {
        this.props.goToStep2(partner1Role, partner1FirstName, partner1LastName);
        this.props.onGoToStep2 && this.props.onGoToStep2();
    }
}

/* Note: we do the redux connect in the container module and not the component module
because typescript is weird about that */
const mapStateToProps = (state: types.InquiryModalCombinedState) => {
    const inquiryFormState = state.form['DWTInquiryForm'] as FormState;
    const authState = state.authState;
    const userData = authState.userData;

    // Show step 2 if any of the form values are unset
    let showStep2 = true;
    if (!!userData) {
        showStep2 =
            !userData.partner1_role ||
            !userData.partner1_first_name ||
            !userData.partner1_last_name ||
            !userData.partner2_role ||
            !userData.partner2_first_name ||
            !userData.partner2_last_name ||
            !userData.country;
    }

    return {
        authState: authState,
        curStep: state.dwtInquiryFormState.curStep,
        initialValues: state.dwtInquiryFormState.initialValues,
        formValues: !!inquiryFormState ? inquiryFormState.values : {},
        datesFlexible: inquiryFormState && inquiryFormState.values ? !!inquiryFormState.values.dates_flexible : false,
        showStep2: showStep2,
        ...state.inquiryFormContainerState,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<types.InquiryModalCombinedState, undefined, AppointmentsActions>) => ({
    loadAuthStatus: (disabledMoments: Date[], datesFlexible: boolean) => {
        loadUserAuthStatusAndUserData(dispatch)
            .then((resp) => {
                updateUserData(dispatch, resp, disabledMoments, datesFlexible);
            })
            .catch(() => {
                /* Do nothing*/
            });
    },
    loadUserData: (disabledMoments: Date[], datesFlexible: boolean) => {
        loadUserDataOnly(dispatch)
            .then((resp) => {
                updateUserData(dispatch, resp, disabledMoments, datesFlexible);
            })
            .catch(() => {
                /* Do nothing*/
            });
    },
    loadVenueData: (venueId: number) => {
        dispatch(loadVenueData(venueId));
    },
    updateFormUserData: (userData: Auth.UserInfo, disabledMoments: Date[], datesFlexible: boolean) => {
        updateUserData(dispatch, userData, disabledMoments, datesFlexible);
    },
    updateForm: (field: string, value: any) => {
        dispatch(change('DWTInquiryForm', field, value));
    },
    goToStep1: () => {
        dispatch(dispatchDWTFormStep1());
    },
    goToStep2: (partner1Role?: string, partner1FirstName?: string, partner1LastName?: string) => {
        if (!!partner1Role) {
            dispatch(change('DWTInquiryForm', 'partner1_role', partner1Role));
        }
        if (!!partner1FirstName) {
            dispatch(change('DWTInquiryForm', 'partner1_first_name', partner1FirstName));
        }
        if (!!partner1LastName) {
            dispatch(change('DWTInquiryForm', 'partner1_last_name', partner1LastName));
        }
        dispatch(dispatchDWTFormStep2());
    },
    formSubmitSuccess: () => {
        dispatch(dispatchDWTFormSuccess());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DWTInquiryFormContainer);
