import * as React from 'react';
import { reduxForm, InjectedFormProps, FormSubmitHandler, Form, FormErrors } from 'redux-form';
import LoadingSpinner from '../LoadingSpinner';

export interface FormData {
    get_pricing_1: boolean;
    get_pricing_2: boolean;
    get_pricing_3: boolean;
    get_pricing_4: boolean;
    get_pricing_5: boolean;
    get_pricing_6: boolean;
}

/* Typedefs */
interface OwnProps {
    submitHandler: FormSubmitHandler;
    handleRejectExtraOutreach: () => void;
    primaryCopy: string;
    secondaryCopy?: string;

    hotelsLoading?: boolean;
}

export type OutreachPushFormProps = InjectedFormProps<FormData, OwnProps> & OwnProps;

class OutreachPushForm extends React.Component<OutreachPushFormProps> {
    renderCTA() {
        const { submitting } = this.props;

        return (
            <>
                <div className={`OutreachPushForm--form--spinner-container`}>
                    <button type='submit' className={`nx-button nx-button--primary`} disabled={submitting || this.props.invalid}>
                        {this.props.primaryCopy}
                    </button>
                    {submitting && <LoadingSpinner />}
                </div>

                {this.props.secondaryCopy && (
                    <a onClick={this.props.handleRejectExtraOutreach} className={`OutreachPushForm--form--link-negative`}>
                        {this.props.secondaryCopy}
                    </a>
                )}
            </>
        );
    }

    render() {
        const { handleSubmit, submitHandler, children, hotelsLoading } = this.props;

        return (
            <div className='OutreachPushForm'>
                {hotelsLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Form className='OutreachPushForm--form' onSubmit={handleSubmit(submitHandler)}>
                        <div className={`OutreachPushForm--form--top-cta`}>{this.renderCTA()}</div>
                        {children}
                        <div className={`OutreachPushForm--form--bottom-cta`}>{this.renderCTA()}</div>
                    </Form>
                )}
            </div>
        );
    }
}

const formValidator = (formData: FormData) => {
    const errors: FormErrors<FormData> = {};

    const keys = Object.keys(formData);

    if (keys.length > 0 && !keys.map((key) => formData[key]).some((v) => v === true)) {
        errors._error = 'Select some venue to proceed';
    }
    return errors;
};

export default reduxForm<FormData, OwnProps>({
    form: 'OutreachPushForm',
    touchOnBlur: false,
    validate: formValidator,
})(OutreachPushForm);
