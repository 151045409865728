import React from 'react';

import './scss/Review.module.scss';

/**
 *
 * @returns A "NEW" ui component which we can display to denote the review is new
 */
const NewTag: React.FC = () => <div className='ReviewCard--tagNew'>New</div>;

export default NewTag;
