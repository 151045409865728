/**
 * multi-step widget w/ inquiry, connect account, recommendations and hotels steps
 * https://wiki.cvent.com/pages/viewpage.action?spaceKey=WS&title=SpeedRFP+integration+task+list&focusedCommentId=257739669&refresh=1596130386505#comment-257739669
 */
import * as React from 'react';

import HotelIcon from '@cvent/carina/components/Icon/Hotel';
import { chunk } from '@weddingspot/ws-utils';
import * as types from './types';
import ConnectAccountFormContainer from './ConnectAccountFormContainer';
import VenueRecommendationsCell from '../recommendations/VenueRecommendationsCell';
import VenueRecommendationsHeroCell from '../recommendations/VenueRecommendationsHeroCell';
import Loading from '../LoadingSpinner';
import { classNames as cls } from '../../utils/dom';

import './scss/inquiry-widget.module.scss';
import { InquiryType } from './constants';
import { Venues } from '@weddingspot/ws-api-client';
import OutreachPushFormContainer from './OutreachPushFormContainer';
import OutreachHotelsFormContainer from './OutreachHotelsFormContainer';
import { InquiryHotelFormConnected, VenueData } from './RFPHotelForm';

export interface InquiryFormProps {
    venueId: number;
    handleInquirySubmitSuccess?: (isQuoteRequest: boolean) => void;
    handleClickModalClose: () => void;
    isEmailExists?: () => void;
    showGuestRoomsRecommendations?: boolean;
    showWsRfpForm?: boolean;
    testGuestRoomsIntentCta?: 'A' | 'B';
    intentCTAonPostOutreachStep1?: 'A' | 'B';
    intentCTAStepRemoved?: 'A' | 'B';
}

interface OwnProps extends InquiryFormProps {
    venueName: string;
    isQuoteRequest: boolean;
    isDestination?: boolean;
    userIsLoggedIn: boolean;
    isExistingUser: boolean;
    userEmail: string;
    inquiryType?: InquiryType;
    isWidgetFlow?: boolean; // whether it is a SE Widget flow or not
    renderInquiryForm: (props: InquiryFormProps) => React.ReactElement<InquiryFormProps>;

    handleNavigatedToConnectAccount?: () => void;
    handleNavigatedToPostOutreachScreen?: () => void;
    handleConnectAccountSuccess?: () => void;
    handleRecommendationsClick?: (position: number, recommendations: Venues.VenueRecommendation[]) => void;
    handleAbandonmentCta?: () => void;
    handleAbandonmentDismiss?: () => void;

    navigateToInquiryForm: () => void;
    navigateToPasswordForm: () => void;
    loadRecommendations: (venueId: number, inquiryType?: InquiryType) => void;
    loadHotelRecommendations: (venueId: number) => void;
    navigateToRecommendations: () => void;
    navigateToHotelRecommendations: () => void;
    navigateToHotelForm: () => void;
    navigateToAbandonment: () => void;
    navigateToSuccessScreen: () => void;
    navigateToSeeNearbyHotelsScreen: () => void;
    submitOutreachPushForm?: () => void;
    pushOutreachNoThanksClicked?: () => void;
    pushOutreachSuccessBrowseMoreClicked?: () => void;
    pushOutreachInquireClicked?: (position: number[]) => void;
    pushIntentCTAonPostOutreachStep1?: () => void;
    navigateToClosestSRP?: () => void;
    handleNavigatedToSeeNearbyHotels?: () => void;
    handleNavigatedToHotels?: (count: number) => void;
    handleRejectSeeHotels?: () => void;
    handleRejectHotelsRecommended?: () => void;
    handleHotelsRecommendedRFP?: () => void;
    handleHotelsRecommendedForm?: (source: string, count: number) => void;
    handleRoomCountSubmit?: (params: object) => void;
    handleClickedSpecifyGuestRooms?: () => void;

    isEmailExists?: () => void;
}

export type InquiryWidgetProps = OwnProps & types.InquiryModalState;

interface InquiryWidgetState {
    hotelsSelected: VenueData[];
}

export class InquiryWidget extends React.Component<InquiryWidgetProps> {
    state: InquiryWidgetState = {
        hotelsSelected: [],
    };

    constructor(props: InquiryWidgetProps) {
        super(props);
        this.handleRecommendationClick = this.handleRecommendationClick.bind(this);
        this.handleClickAbandonmentCta = this.handleClickAbandonmentCta.bind(this);
        this.handleClickAbandonmentDismiss = this.handleClickAbandonmentDismiss.bind(this);
        this.handleReturnToSpotEstimateClick = this.handleReturnToSpotEstimateClick.bind(this);
        this.renderRdv = this.renderRdv.bind(this);
        this.isPushFlow = this.isPushFlow.bind(this);
        this.submitOutreachPushForm = this.submitOutreachPushForm.bind(this);
        this.handleRejectExtraOutreach = this.handleRejectExtraOutreach.bind(this);
        this.handleRejectHotelsRecommended = this.handleRejectHotelsRecommended.bind(this);
        this.handleConnectAccountSkipOrSuccess = this.handleConnectAccountSkipOrSuccess.bind(this);
        this.handleExtraOutreachSuccess = this.handleExtraOutreachSuccess.bind(this);
        this.navigateToRecommendations = this.navigateToRecommendations.bind(this);
        this.navigateToClosestSRP = this.navigateToClosestSRP.bind(this);
        this.handleBrowseMoreClicked = this.handleBrowseMoreClicked.bind(this);
        this.handleSeeNearbyHotelsClicked = this.handleSeeNearbyHotelsClicked.bind(this);
        this.goToPasswordOrEndOfFlow = this.goToPasswordOrEndOfFlow.bind(this);
        this.goToHotelsFlow = this.goToHotelsFlow.bind(this);
    }

    componentDidMount() {
        // Load recs ASAP
        this.props.loadRecommendations(this.props.venueId, this.props.inquiryType);
        this.props.loadHotelRecommendations(this.props.venueId);
    }

    componentDidUpdate(prevProps: InquiryWidgetProps) {
        switch (this.props.curStep) {
            case types.InquiryModalStage.INQUIRY_FORM:
                if (this.props.inquiryFormSuccess) {
                    if (!this.props.userIsLoggedIn && !this.props.userEmail && !this.props.isWidgetFlow && !this.props.isExistingUser) {
                        this.props.navigateToPasswordForm();
                        this.props.handleNavigatedToConnectAccount && this.props.handleNavigatedToConnectAccount();
                    } else {
                        this.navigateToRecommendations();
                    }
                }
                break;
            case types.InquiryModalStage.PASSWORD_FORM:
                if (this.props.passwordFormSuccess || this.props.passwordFormCancel) {
                    if (
                        prevProps.passwordFormSuccess !== this.props.passwordFormSuccess ||
                        prevProps.passwordFormCancel !== this.props.passwordFormCancel
                    ) {
                        this.handleConnectAccountSkipOrSuccess();
                    }
                }
                break;
            case types.InquiryModalStage.ABANDONMENT:
                this.props.navigateToAbandonment();
                break;
            case types.InquiryModalStage.SUCCESS:
                this.props.navigateToSuccessScreen();
                break;
            case types.InquiryModalStage.SEE_NEARBY_HOTELS:
                this.props.navigateToSeeNearbyHotelsScreen();
                break;
            default:
                break;
        }
        // Load recs ASAP
        if (prevProps.inquiryType !== this.props.inquiryType) {
            this.props.loadRecommendations(this.props.venueId, this.props.inquiryType);
        }
    }

    handleReturnToSpotEstimateClick() {
        if (!!this.props.handleClickModalClose) {
            this.props.handleClickModalClose();
        }
    }

    handleRejectExtraOutreach() {
        if (this.props.intentCTAonPostOutreachStep1 === 'B') {
            !!this.props.pushIntentCTAonPostOutreachStep1 && this.props.pushIntentCTAonPostOutreachStep1();
            this.goToHotelsFlow(false, true);
        } else {
            !!this.props.pushOutreachNoThanksClicked && this.props.pushOutreachNoThanksClicked();
            this.goToHotelsFlow(false);
        }
    }

    handleRejectHotelsRecommended() {
        !!this.props.handleRejectHotelsRecommended && this.props.handleRejectHotelsRecommended();
        this.goToPasswordOrEndOfFlow(false);
    }

    isPushFlow() {
        return !this.props.isWidgetFlow;
    }

    submitOutreachPushForm() {
        return !!this.props.submitOutreachPushForm;
    }

    renderRecommendations() {
        const venueCards = this.props.recommendations.slice(this.isPushFlow() ? 0 : 1, this.isPushFlow() ? 6 : 5);
        return this._renderRecommendations(venueCards);
    }

    renderHotelRecommendations() {
        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--recommendations hotelRecommendations'>
                    <div className='InquiryWidget--recommendations--top-text'>
                        Explore these hotels to find the best rooms for your guests
                    </div>
                    <OutreachHotelsFormContainer
                        seedVenueId={this.props.venueId}
                        handleRejectExtraOutreach={this.handleRejectHotelsRecommended}
                        handleExtraOutreachSuccess={this.handleHotelsOutreachSuccess}
                        handleRender={() => {
                            !!this.props.handleNavigatedToHotels && this.props.handleNavigatedToHotels(this.props.hotels.length);
                        }}
                        venueIds={[]}
                        venueCSNIds={this.props.hotels.map((hotel) => hotel.csn_id)}
                        primaryCopy='Request Quote'
                        secondaryCopy='I’m good with wedding venue recommendations'
                        showWsRfpForm={this.props.showWsRfpForm}
                    >
                        {this.renderHotelRecommendationsContent()}
                    </OutreachHotelsFormContainer>
                </div>
            </div>
        );
    }

    renderHotelRecommendationForm() {
        return (
            <InquiryHotelFormConnected
                type='inquiry'
                csnVenueData={this.state.hotelsSelected}
                onClose={() => this.goToPasswordOrEndOfFlow(false)}
                onSuccess={() => {
                    this.goToPasswordOrEndOfFlow(true);
                    this.props.handleHotelsRecommendedForm &&
                        this.props.handleHotelsRecommendedForm('post outreach modal', this.state.hotelsSelected.length);
                }}
                handleRoomCountSubmit={this.props.handleRoomCountSubmit}
                handleClickedSpecifyGuestRooms={this.props.handleClickedSpecifyGuestRooms}
                leadSource='post outreach'
            />
        );
    }

    _renderRecommendations(venueCards: Venues.VenueRecommendation[]) {
        const textUnderTitle =
            this.props.intentCTAonPostOutreachStep1 === 'B'
                ? `Great job! Continue to find more venues or start searching for hotels to reserve
                rooms for your wedding guests`
                : `Inquiry sent!
                Our recommendations are based on site usage and have helped other couples find their dream venue.
                And now it’s your turn to find the perfect match. Give it a try!`;
        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--recommendations'>
                    <div className='InquiryWidget--recommendations--top-text'>
                        {!this.isPushFlow() ? (
                            <>
                                Your message has been sent to{' '}
                                {this.props.isDestination && (
                                    <span>
                                        <b>DestinationWeddings.com</b> for{' '}
                                    </span>
                                )}
                                <b>{this.props.venueName}</b>.{!!this.props.isWidgetFlow && <br />}
                                You'll be notified when they respond.
                                {this.props.recommendations.length > 0 &&
                                    !this.props.isWidgetFlow &&
                                    " Meanwhile, check out some similar venues we've found for you:"}
                                {!!this.props.isWidgetFlow && (
                                    <div>
                                        <br />
                                        <button
                                            onClick={this.handleReturnToSpotEstimateClick}
                                            className={`return-to-spot-estimate-button nx-button nx-button--primary`}
                                        >
                                            Return to my estimate
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            textUnderTitle
                        )}
                    </div>
                    {!this.props.isWidgetFlow &&
                        (!this.isPushFlow() ? (
                            this.renderRecommendationsContent(venueCards)
                        ) : (
                            <OutreachPushFormContainer
                                inquiryFormData={this.props.inquiryFormData}
                                handleRejectExtraOutreach={this.handleRejectExtraOutreach}
                                handleExtraOutreachSuccess={this.handleExtraOutreachSuccess}
                                venueIds={venueCards.map((rec) => rec.id)}
                                primaryCopy='Get pricing!'
                                secondaryCopy={
                                    this.props.intentCTAonPostOutreachStep1 === 'B' && this.props.hotelsLoaded
                                        ? 'Start exploring hotel rooms'
                                        : 'I’m good with only one option, thanks'
                                }
                                hotelsLoading={false}
                            >
                                {this.renderRecommendationsContent(venueCards)}
                            </OutreachPushFormContainer>
                        ))}
                </div>
            </div>
        );
    }

    goToHotelsFlow(success: boolean, forceToHotels?: boolean) {
        if (!!this.props.showGuestRoomsRecommendations) {
            if (this.props.hotels.length) {
                if (forceToHotels || this.props.intentCTAStepRemoved === 'B') {
                    this.props.navigateToHotelRecommendations();
                } else {
                    this.props.navigateToSeeNearbyHotelsScreen();
                    this.props.handleNavigatedToSeeNearbyHotels && this.props.handleNavigatedToSeeNearbyHotels();
                }
            } else {
                this.goToPasswordOrEndOfFlow(success);
            }
        } else {
            this.goToPasswordOrEndOfFlow(success);
        }
    }

    handleExtraOutreachSuccess(positions: number[]) {
        !!this.props.pushOutreachInquireClicked && this.props.pushOutreachInquireClicked(positions);
        this.goToHotelsFlow(true);
    }

    handleHotelsOutreachSuccess = (positions: number[]) => {
        if (this.props.showWsRfpForm) {
            this.setState({
                hotelsSelected: positions
                    .map((pos) => {
                        const hotel = this.props.hotels[pos - 1];
                        if (hotel) {
                            return {
                                id: hotel.csn_id,
                                meta: {
                                    name: hotel.name,
                                    location: `${hotel.city}, ${hotel.state}`,
                                    previewImg: hotel.image,
                                },
                            };
                        }
                        return undefined;
                    })
                    .filter((id) => id),
            });
            this.props.navigateToHotelForm();
        } else {
            this.goToPasswordOrEndOfFlow(true);
        }
        this.props.handleHotelsRecommendedRFP && this.props.handleHotelsRecommendedRFP();
    };

    navigateToRecommendations() {
        this.props.navigateToRecommendations();
        if (this.isPushFlow()) {
            !!this.props.handleNavigatedToPostOutreachScreen && this.props.handleNavigatedToPostOutreachScreen();
        }
    }

    goToPasswordOrEndOfFlow(success: boolean) {
        if (!this.props.userIsLoggedIn && !!this.props.userEmail && !this.props.isExistingUser) {
            this.props.navigateToPasswordForm();
            this.props.handleNavigatedToConnectAccount && this.props.handleNavigatedToConnectAccount();
        } else if (success) {
            this.props.navigateToSuccessScreen();
        } else {
            this.navigateToClosestSRP();
        }
    }

    handleConnectAccountSkipOrSuccess() {
        if (this.isPushFlow()) {
            this.handleReturnToSpotEstimateClick();
        } else {
            this.navigateToRecommendations();
        }
    }

    renderRecommendationsContent(venueCards: Venues.VenueRecommendation[]) {
        const positionAdd = this.isPushFlow() ? 0 : 1;
        const rowLength = this.isPushFlow() ? 3 : 2;

        return (
            <div className='InquiryWidget--recommendations--recommendations-wrapper'>
                {!this.props.recommendationsLoaded && <Loading />}
                {this.props.recommendationsLoaded && this.props.recommendations.length > 0 && (
                    <div>
                        {!this.isPushFlow() && (
                            <div className='nx-hide-for-small-only'>
                                <VenueRecommendationsHeroCell
                                    position={0}
                                    onClick={this.handleRecommendationClick}
                                    {...this.props.recommendations[0]}
                                    budget_capacity={this.renderRdv() ? undefined : this.props.recommendations[0].budget_capacity}
                                    budget_min={this.renderRdv() ? undefined : this.props.recommendations[0].budget_min}
                                />
                            </div>
                        )}
                        <div
                            className={cls(
                                'InquiryWidget--recommendations--flex-container',
                                this.isPushFlow() ? '' : 'nx-hide-for-small-only',
                                this.isPushFlow() ? 'InquiryWidget--recommendations--flex-container-push' : ''
                            )}
                        >
                            {this.props.recommendations &&
                                chunk(venueCards, rowLength).map(
                                    // pairwise(venueCards).map(
                                    (row, idx) => (
                                        <div className='InquiryWidget--recommendations--row' key={idx}>
                                            {row.map((item, innerIndex) => {
                                                return (
                                                    <div key={innerIndex} className='InquiryWidget--recommendations--col'>
                                                        <VenueRecommendationsCell
                                                            position={idx * rowLength + positionAdd + innerIndex}
                                                            onClick={this.handleRecommendationClick}
                                                            {...item}
                                                            budget_capacity={this.renderRdv() ? undefined : item.budget_capacity}
                                                            budget_min={this.renderRdv() ? undefined : item.budget_min}
                                                            outreachPush={this.isPushFlow()}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                )}
                        </div>
                        {!this.isPushFlow() && (
                            <div className={cls('InquiryWidget--recommendations--flex-container', 'nx-show-for-small-only')}>
                                {this.props.recommendations.slice(0, 5).map((rec, idx) => (
                                    <div className='InquiryWidget--recommendations--row' key={idx}>
                                        <div className='InquiryWidget--recommendations--col'>
                                            <VenueRecommendationsCell position={idx} onClick={this.handleRecommendationClick} {...rec} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    renderHotelRecommendationsContent() {
        const list = this.props.hotels.map((item, idx) => {
            return (
                <VenueRecommendationsCell
                    key={idx}
                    position={idx}
                    onClick={this.handleRecommendationClick}
                    outreachPush={true}
                    csn_id={item.csn_id}
                    name={item.name}
                    region={`${item.city}, ${item.state}`} // todo
                    thumbnail_url={item.image}
                    distance={item.distance}
                    typeLabel={item.type}
                />
            );
        });

        return (
            <div className='InquiryWidget--recommendations--recommendations-wrapper'>
                <div>
                    <div
                        className={cls(
                            'InquiryWidget--recommendations--flex-container-push',
                            'is-more-recommendations',
                            'InquiryWidget--hotel-recommendations'
                        )}
                    >
                        {list}
                    </div>
                </div>
            </div>
        );
    }

    renderPasswordForm() {
        const { userEmail, massInquiryFormSuccess } = this.props;

        if (!userEmail) {
            return (
                <div className='InquiryWidget'>
                    <div className='InquiryWidget--password'>
                        <div className='InquiryWidget--password--top-text'>There was an error, please refresh the page.</div>
                    </div>
                </div>
            );
        }

        const isRFPSuccess = (this.props.hotelsInquirySuccess && !this.props.showWsRfpForm) || this.props.modernRFPFormSuccess;

        const textBlock = isRFPSuccess ? (
            'Thank you for using Wedding Spot for your guest room needs! You will receive follow-up emails from Cvent about the room block requests'
        ) : massInquiryFormSuccess ? (
            'Your inquiries have been sent'
        ) : (
            <>
                Your message has been sent to{' '}
                {this.props.isDestination ? (
                    <span>
                        <b>DestinationWeddings.com</b> for <b>{this.props.venueName}</b>
                    </span>
                ) : (
                    <b>{this.props.venueName}</b>
                )}
            </>
        );

        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--password'>
                    <div className='InquiryWidget--password--top-text'>
                        {textBlock}. Create a <b>free</b> account now so you can easily keep track of the venues you contacted and selected
                        as favorites.
                    </div>

                    <div className='InquiryWidget--password--form-wrapper'>
                        <ConnectAccountFormContainer
                            isMultipleInquiryFlow={massInquiryFormSuccess}
                            userEmail={userEmail}
                            skipHandler={() => {
                                this.handleConnectAccountSkipOrSuccess();
                            }}
                            onConnectSuccess={() => {
                                this.props.handleConnectAccountSuccess && this.props.handleConnectAccountSuccess();
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderAbandonment() {
        return (
            <div className='InquiryWidget'>
                <div className='InquiryWidget--abandonment'>
                    <div className='InquiryWidget--abandonment--text'>
                        This beautiful venue is only a couple of fields away! Give it another try? 😬🙏
                    </div>
                    <div className='InquiryWidget--abandonment--actions'>
                        <a href={undefined} className='InquiryWidget--abandonment--dismiss' onClick={this.handleClickAbandonmentDismiss}>
                            Thank you, next
                        </a>
                        <button
                            className='InquiryWidget--abandonment--cta nx-button nx-button--primary'
                            onClick={this.handleClickAbandonmentCta}
                        >
                            Let's do this!
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    navigateToClosestSRP() {
        !!this.props.navigateToClosestSRP && this.props.navigateToClosestSRP();
    }

    handleBrowseMoreClicked() {
        !!this.props.pushOutreachSuccessBrowseMoreClicked && this.props.pushOutreachSuccessBrowseMoreClicked();
        this.navigateToClosestSRP();
    }

    handleRejectSeeHotels = () => {
        !!this.props.handleRejectSeeHotels && this.props.handleRejectSeeHotels();
        this.goToPasswordOrEndOfFlow(false);
    };

    handleSeeNearbyHotelsClicked() {
        this.props.navigateToHotelRecommendations();
    }

    renderSuccessScreen() {
        if (this.props.prevStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS_RFP_FORM) {
            return (
                <div className='InquiryWidget'>
                    <div className='InquiryWidget--success'>
                        <div className='InquiryWidget--success--text'>
                            Thank you for using Wedding Spot for your guest room needs!
                            <br />
                            You will receive follow-up emails from Cvent about the room block requests.
                        </div>
                        <div className='InquiryWidget--success--actions is-mob-fixed'>
                            <button
                                className='InquiryWidget--success--cta nx-button nx-button--primary'
                                onClick={this.handleBrowseMoreClicked}
                            >
                                Explore Wedding venues
                            </button>
                            <div className='InquiryForm--rfp-close-text' onClick={this.props.handleClickModalClose}>
                                No thanks, I'm good
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <InquiryWidgetSuccess handleOnButtonClick={this.handleSeeNearbyHotelsClicked} />;
    }

    renderSeeNearbyHotelsScreen() {
        const successTextUnderTitle = (
            <div>
                Inquiry sent!
                <b>
                    {` `}
                    {this.props.testGuestRoomsIntentCta === 'B'
                        ? `Make your life easier by requesting a room block for your guests. 
                            Room blocks allow for all of your guests to stay in one place, 
                            saving you time and money. Request a proposal to get the best rates.`
                        : `Interested in finding hotel rooms for your guests?`}
                </b>
            </div>
        );

        return (
            <div className='InquiryWidget hotelRecommendations'>
                <div className='InquiryWidget--success'>
                    <div className='InquiryWidget--success--text'>
                        {this.props.massInquiryFormSuccess
                            ? successTextUnderTitle
                            : `Interested in finding hotel room blocks for your friends and family and ensure that they have a prime place to stay for your big day?`}
                    </div>
                    <div className='InquiryWidget--success--actions'>
                        <button
                            className='InquiryWidget--success--cta nx-button nx-button--primary'
                            onClick={this.handleSeeNearbyHotelsClicked}
                        >
                            <HotelIcon color='#fff' size='m' />
                            <div>See Nearby hotels</div>
                        </button>
                        <a className='InquiryWidget--success-link' onClick={this.handleRejectSeeHotels}>
                            I'm good with only wedding venues, thanks
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { curStep } = this.props;

        if (curStep === types.InquiryModalStage.INQUIRY_FORM) {
            return this.props.renderInquiryForm(this.props);
        } else if (curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS) {
            return this.renderRecommendations();
        } else if (curStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS) {
            return this.renderHotelRecommendations();
        } else if (curStep === types.InquiryModalStage.HOTEL_RECOMMENDATIONS_RFP_FORM) {
            return this.renderHotelRecommendationForm();
        } else if (curStep === types.InquiryModalStage.PASSWORD_FORM) {
            return this.renderPasswordForm();
        } else if (curStep === types.InquiryModalStage.ABANDONMENT) {
            return this.renderAbandonment();
        } else if (curStep === types.InquiryModalStage.SUCCESS) {
            return this.renderSuccessScreen();
        } else if (curStep === types.InquiryModalStage.SEE_NEARBY_HOTELS) {
            return this.renderSeeNearbyHotelsScreen();
        } else {
            return this.props.renderInquiryForm(this.props);
        }
    }

    private handleRecommendationClick(position: number) {
        !!this.props.handleRecommendationsClick && this.props.handleRecommendationsClick(position, this.props.recommendations);
    }

    private handleClickAbandonmentCta() {
        this.props.navigateToInquiryForm();
        !!this.props.handleAbandonmentCta && this.props.handleAbandonmentCta();
    }

    private handleClickAbandonmentDismiss() {
        !!this.props.handleAbandonmentDismiss && this.props.handleAbandonmentDismiss();
    }

    private renderRdv() {
        return this.props.inquiryType === InquiryType.REHEARSAL_DINNER;
    }
}

export const InquiryWidgetSuccess = (props: { handleOnButtonClick?: () => void }) => {
    return (
        <div className='InquiryWidget'>
            <div className='InquiryWidget--success InquiryWidget--weddings-cart-success'>
                <div className='InquiryWidget--success--text'>
                    Your request is sent! Wedding Spot will send updates to the email address you provided.
                </div>
                <div className='InquiryWidget--success--actions'>
                    <button className='InquiryWidget--success--cta nx-button nx-button--primary' onClick={props.handleOnButtonClick}>
                        Explore Wedding venues
                    </button>
                </div>
            </div>
        </div>
    );
};
