import * as React from 'react';
import Card, { CheckoutVenueRecommendationsCardInterface } from './Card';

const styles = require('./Cards.module.scss');

interface CheckoutVenueRecommendationsCardsListInterface {
    list: CheckoutVenueRecommendationsCardInterface[];
    changeIsChecked: (id: number) => void;
}
const CheckoutVenueRecommendationsCardsList = ({ list, changeIsChecked }: CheckoutVenueRecommendationsCardsListInterface) => {
    return (
        <div className={styles.checkoutCards}>
            {list.map((item: CheckoutVenueRecommendationsCardInterface) => (
                <Card card={item} changeIsChecked={changeIsChecked} />
            ))}
        </div>
    );
};

export default CheckoutVenueRecommendationsCardsList;
