import { Dispatch } from 'redux';
import { Auth, Venues, AuthProvider, VendorsApi } from '@weddingspot/ws-api-client';
import * as constants from './constants';
import * as types from './types';

/**
 * Auth actions
 */
export interface UserDataLoadedAction {
    type: constants.USER_DATA_LOADED;
    payload: Auth.UserInfo | {};
}

export interface UserAuthStatusLoadedAction {
    type: constants.USER_AUTH_STATUS_UPDATED;
    payload: boolean;
}

/**
 * Connect user account form actions
 */
export interface UnconnectedUserEmailSetAction {
    type: constants.UNCONNECT_USER_EMAIL_SET;
    payload: string;
}

export interface UnconnectedUserEmailClearAction {
    type: constants.UNCONNECT_USER_EMAIL_CLEAR;
}

/**
 * InquiryForm actions
 */
export interface SetIsExistingUserAction {
    type: constants.IS_EXISTING_USER_SET;
    payload: boolean;
}

export interface VenueDataLoadedAction {
    type: constants.VENUE_DATA_LOADED;
    payload: Venues.VenueDetails;
}

export interface InquiryFormSubmittedAction {
    type: constants.INQUIRY_FORM_SUBMITTED_DATA_SET;
    payload: any;
}

/**
 * DWTInquiryForm actions
 */
export interface GoToInquiryStepAction {
    type: constants.GO_TO_DWT_FORM_STEP;
    payload: types.DWTInquiryFormStep;
}

export interface DWTFormSuccessAction {
    type: constants.DWT_FORM_SUCCESS;
}

/**
 * InquiryModal actions
 */
export interface TransitionToInquiryFormAction {
    type: constants.INQUIRY_MODAL_TRANSITION_INQUIRY_FORM;
}

export interface TransitionToPasswordAction {
    type: constants.INQUIRY_MODAL_TRANSITION_PASSWORD;
}

export interface TransitionToRecommendations {
    type: constants.INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS;
}

export interface TransitionToHotelRecommendations {
    type: constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS;
}

export interface TransitionToHotelRecommendationsForm {
    type: constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM;
}

export interface TransitionToAbandonment {
    type: constants.INQUIRY_MODAL_TRANSITION_ABANDONMENT;
}

export interface TransitionToSuccessScreen {
    type: constants.INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN;
}

export interface TransitionToSeeNearbyHotelsScreen {
    type: constants.INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN;
}

export interface ResetModal {
    type: constants.INQUIRY_MODAL_RESET;
}

export type InquiryModalAction =
    | TransitionToInquiryFormAction
    | TransitionToPasswordAction
    | TransitionToRecommendations
    | TransitionToAbandonment
    | ResetModal;

export type AppointmentsActions =
    | UserDataLoadedAction
    | VenueDataLoadedAction
    | UserAuthStatusLoadedAction
    | UnconnectedUserEmailSetAction
    | UnconnectedUserEmailClearAction
    | SetIsExistingUserAction
    | InquiryModalAction;

/**
 * Dispatchers
 */

export function loadUserAuthStatusOnly() {
    return (dispatch: Dispatch<UserAuthStatusLoadedAction>) => {
        AuthProvider.isLoggedIn()
            .then((resp) =>
                dispatch({
                    type: constants.USER_AUTH_STATUS_UPDATED,
                    payload: resp === 'authed',
                })
            )
            .catch(() => {});
    };
}

export function loadUserDataOnly(dispatch: Dispatch<UserDataLoadedAction>): Promise<Auth.UserInfo> {
    return new Promise<Auth.UserInfo>((resolve, reject) => {
        AuthProvider.userInfo()
            .then((resp) => {
                dispatch({
                    type: constants.USER_DATA_LOADED,
                    payload: resp,
                });
                resolve(resp);
            })
            .catch(() => {
                dispatch({
                    type: constants.USER_DATA_LOADED,
                    payload: {},
                });
                reject();
            });
    });
}

export function loadUserAuthStatusAndUserData(
    dispatch: Dispatch<UserDataLoadedAction | UserAuthStatusLoadedAction>
): Promise<Auth.UserInfo> {
    return new Promise((resolve, reject) => {
        AuthProvider.isLoggedIn()
            .then((resp) => {
                let authed = resp === 'authed';
                dispatch({
                    type: constants.USER_AUTH_STATUS_UPDATED,
                    payload: authed,
                });
                if (authed) {
                    AuthProvider.userInfo()
                        .then((resp) => {
                            dispatch({
                                type: constants.USER_DATA_LOADED,
                                payload: resp,
                            });
                            resolve(resp);
                        })
                        .catch(() => {});
                } else {
                    dispatch({
                        type: constants.USER_DATA_LOADED,
                        payload: {},
                    });
                    reject();
                }
            })
            .catch(() => {
                reject();
            });
    });
}

export function loadVenueData(venueId: number) {
    return (dispatch: Dispatch<VenueDataLoadedAction>) => {
        return new Promise((resolve, reject) => {
            VendorsApi.venueDetails(venueId)
                .then((venueData: Venues.VenueDetails) => {
                    dispatch({
                        type: constants.VENUE_DATA_LOADED,
                        payload: venueData,
                    });
                    resolve(venueData);
                })
                .catch(() => {
                    reject();
                });
        });
    };
}

export function createSetUnconnectedUserEmailAction(email: string) {
    return (dispatch: Dispatch<UnconnectedUserEmailSetAction>) => {
        dispatch({
            type: constants.UNCONNECT_USER_EMAIL_SET,
            payload: email,
        });
    };
}

export function createSetIsExistingUserAction(val: boolean) {
    return (dispatch: Dispatch<SetIsExistingUserAction>) => {
        dispatch({
            type: constants.IS_EXISTING_USER_SET,
            payload: val,
        });
    };
}

export function createClearUnconnectedUserEmailAction() {
    return (dispatch: Dispatch<UnconnectedUserEmailClearAction>) => {
        dispatch({
            type: constants.UNCONNECT_USER_EMAIL_CLEAR,
        });
    };
}

export function setInquiryFormSubmittedData(data: object) {
    return (dispatch: Dispatch<InquiryFormSubmittedAction>) => {
        dispatch({
            type: constants.INQUIRY_FORM_SUBMITTED_DATA_SET,
            payload: data,
        });
    };
}

/**
 * InquiryModal action creators
 */
export function createTransitionInquiryFormAction() {
    return (dispatch: Dispatch<TransitionToInquiryFormAction>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_INQUIRY_FORM });
    };
}

export function createTransitionPasswordAction() {
    return (dispatch: Dispatch<TransitionToPasswordAction>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_PASSWORD });
    };
}

export function createTransitionRecommendationsAction() {
    return (dispatch: Dispatch<TransitionToRecommendations>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_RECOMMENDATIONS });
    };
}

export function createTransitionHotelRecommendationsAction() {
    return (dispatch: Dispatch<TransitionToHotelRecommendations>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS });
    };
}

export function createTransitionHotelRecommendationsFormAction() {
    return (dispatch: Dispatch<TransitionToHotelRecommendationsForm>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_HOTEL_RECOMMENDATIONS_FORM });
    };
}

export function createTransitionAbandonmentAction() {
    return (dispatch: Dispatch<TransitionToAbandonment>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_ABANDONMENT });
    };
}

export function createTransitionSuccessScreenAction() {
    return (dispatch: Dispatch<TransitionToSuccessScreen>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_SUCCESS_SCREEN });
    };
}

export function createTransitionSeeNearbyHotelsScreenAction() {
    return (dispatch: Dispatch<TransitionToSeeNearbyHotelsScreen>) => {
        dispatch({ type: constants.INQUIRY_MODAL_TRANSITION_SEE_NEARBY_HOTELS_SCREEN });
    };
}

export function createResetModalAction() {
    return (dispatch: Dispatch<ResetModal>) => {
        dispatch({ type: constants.INQUIRY_MODAL_RESET });
    };
}

/**
 * DWTInquiryForm action creators
 */
export function dispatchDWTFormStep1() {
    return (dispatch: Dispatch<GoToInquiryStepAction>) => {
        dispatch({
            type: constants.GO_TO_DWT_FORM_STEP,
            payload: types.DWTInquiryFormStep.INQUIRY_DETAILS,
        });
    };
}

export function dispatchDWTFormStep2() {
    return (dispatch: Dispatch<GoToInquiryStepAction>) => {
        dispatch({
            type: constants.GO_TO_DWT_FORM_STEP,
            payload: types.DWTInquiryFormStep.COUPLE_DETAILS,
        });
    };
}

// Custom event for tracking successfull DWT form submission
// This is necessary because redux-form triggers the submit success
// event for each stage of the form and there's not a idiomatic way
// to indicate which step succeeded.
export function dispatchDWTFormSuccess() {
    return (dispatch: Dispatch<DWTFormSuccessAction>) => {
        dispatch({
            type: constants.DWT_FORM_SUCCESS,
        });
    };
}
