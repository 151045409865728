import * as React from 'react';
import { reduxForm, Field, InjectedFormProps, FormSubmitHandler } from 'redux-form';

import { TERMS_URL, PRIVACY_POLICY_URL } from './constants';
import { required, createValidator } from '../../utils/validators';
import MaterialTextInput from '../form/MaterialTextInput';

import './scss/connect-account-form.module.scss';

/* Typedefs */
interface OwnProps {
    userEmail: string;
    isMultipleInquiryFlow: boolean;

    submitHandler: FormSubmitHandler;
    skipHandler?: () => void;
}

export interface FormData {
    password: string;
}

export type ConnectAccountFormProps = InjectedFormProps<FormData, OwnProps> & OwnProps;

/* Constants */
const requiredFieldValidator = createValidator(required, 'This field is required');

/**
 * Form for setting password for an unconnected user. This is typically
 * used to set a password for a new user who just sent an unauthenticated
 * inquiry/quote request.
 */
const ConnectAccountForm: React.SFC<ConnectAccountFormProps> = (props) => {
    const { handleSubmit, submitHandler, error, skipHandler, submitting, pristine, userEmail, isMultipleInquiryFlow } = props;

    return (
        <div className='ConnectAccountForm'>
            <form className='ConnectAccountForm--form' onSubmit={handleSubmit(submitHandler)}>
                <div className='ConnectAccountForm--fields-container'>
                    <div className='ConnectAccountForm--saved-email-container'>
                        <div className='ConnectAccountForm--saved-email-lbl'>Account email:</div>
                        <div className='ConnectAccountForm--saved-email'>{`${userEmail}`}</div>
                    </div>

                    <Field
                        name='password'
                        label='Set a password'
                        validate={[requiredFieldValidator]}
                        component={MaterialTextInput}
                        placeholder='Set a password'
                        {...{ inputType: 'password' }}
                    />

                    {error && <div className='ConnectAccountForm--error'>{error}</div>}
                </div>

                <div className='ConnectAccountForm--cta-container'>
                    {!isMultipleInquiryFlow && (
                        <div className='ConnectAccountForm--terms-text'>
                            By creating an account, you accept our{` `}
                            <a href={TERMS_URL}>Terms of Service</a> and <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>.
                        </div>
                    )}

                    <button className='ConnectAccountForm--submit-btn nx-button nx-button--primary' disabled={pristine || submitting}>
                        Create FREE Account
                    </button>

                    {!isMultipleInquiryFlow && (
                        <a className='ConnectAccountForm--skip-btn' onClick={skipHandler}>
                            Skip
                        </a>
                    )}
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: 'ConnectAccountForm',
    touchOnBlur: false, // Prevents validation on blur
})(ConnectAccountForm);
