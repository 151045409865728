import * as React from 'react';

import * as types from './types';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { InquiryWidget } from './InquiryWidget';
import InquiryFormWidget from './InquiryFormWidget';
import {
    InquiryModalAction,
    createResetModalAction,
    createTransitionPasswordAction,
    createTransitionRecommendationsAction,
    createTransitionAbandonmentAction,
    createTransitionInquiryFormAction,
    createTransitionSuccessScreenAction,
    createTransitionHotelRecommendationsAction,
    createTransitionSeeNearbyHotelsScreenAction,
    createTransitionHotelRecommendationsFormAction,
} from './actions';
import { loadHotelRecommendationsAction, loadVenueRecommendationsAction } from '../recommendations/actions';

import './scss/inquiry-modal.module.scss';
import { InquiryType } from './constants';
import { Venues } from '@weddingspot/ws-api-client';

import { StorableDataKeys } from '../../utils/storage';
import LocalStorage from '../../utils/storage';

interface OwnProps {
    // Container props
    renderWidget?: (content: JSX.Element) => JSX.Element;
    renderHeader?: (content: string) => JSX.Element;

    // Redux-connected props
    resetWidget: () => void;

    // pass though InquiryFormProps
    venueId: number;
    inquiryType?: InquiryType;
    handleInquirySubmitSuccess?: (isQuoteRequest: boolean) => void;

    // pass through InquiryWidgetProps (minus render form function)
    venueName: string;
    isQuoteRequest: boolean;
    userIsLoggedIn: boolean;
    userEmail: string;
    isExistingUser: boolean;

    handleConnectAccountSuccess?: () => void;
    handleRecommendationsClick?: (position: number, recommendation?: Venues.VenueRecommendation[]) => void;
    handleAbandonmentShown?: () => void;
    handleAbandonmentCta?: () => void;
    handleAbandonmentDismiss?: () => void;
    handleClickModalClose: () => void;

    navigateToInquiryForm: () => void;
    navigateToPasswordForm: () => void;
    loadRecommendations: (venueId: number, inquiryType?: InquiryType) => void;
    loadHotelRecommendations: (venueId: number) => void;
    navigateToRecommendations: () => void;
    navigateToHotelRecommendations: () => void;
    navigateToHotelForm: () => void;
    navigateToAbandonment: () => void;
    navigateToSuccessScreen: () => void;
    navigateToSeeNearbyHotelsScreen: () => void;
    handleRoomCountSubmit: (params: object) => void;
    handleClickedSpecifyGuestRooms: () => void;
}

export type InquiryWidgetContainerProps = OwnProps & types.InquiryModalState;

export class InquiryWidgetContainerContent<T extends InquiryWidgetContainerProps> extends React.Component<T> {
    constructor(props: T) {
        super(props);
        this.getHeaderContent = this.getHeaderContent.bind(this);
        this.renderWidget = this.renderWidget.bind(this);
    }

    render() {
        return (
            <div>
                {!!this.props.renderHeader && this.props.renderHeader(this.getHeaderContent())}
                {!!this.props.renderWidget ? this.props.renderWidget(this.renderWidget()) : this.renderWidget()}
            </div>
        );
    }

    private renderWidget() {
        return (
            <InquiryWidget
                renderInquiryForm={(props) => {
                    return <InquiryFormWidget {...props} />;
                }}
                {...this.props}
            />
        );
    }

    private getHeaderContent() {
        const { curStep, userEmail } = this.props;

        if (curStep === types.InquiryModalStage.ABANDONMENT) {
            return "You're so close!";
        } else if (curStep === types.InquiryModalStage.PASSWORD_FORM) {
            if (!userEmail) {
                return 'Oops!';
            }
            return 'Save Your Information';
        } else if (curStep === types.InquiryModalStage.VENUE_RECOMMENDATIONS) {
            return 'Success!';
        } else {
            return `Contact ${this.props.venueName}`;
        }
    }
}

const mapStateToProps = (state: types.InquiryModalCombinedState) => {
    const modalState = state.inquiryModalState;
    const recState = state.venueRecommendations;
    const inquiryFormContainer = state.inquiryFormContainerState;
    return {
        ...modalState,
        userIsLoggedIn: state.authState.userIsLoggedIn,
        userEmail: state.authState.unconnectedUserEmail,
        isExistingUser: state.authState.isExistingUser,
        recommendations: recState.recommendations,
        venueName: inquiryFormContainer.venueName,
        isQuoteRequest: inquiryFormContainer.isQuoteRequest,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<types.InquiryModalCombinedState, undefined, InquiryModalAction>) => ({
    navigateToInquiryForm: () => {
        dispatch(createTransitionInquiryFormAction());
    },
    navigateToPasswordForm: () => {
        dispatch(createTransitionPasswordAction());
    },
    loadRecommendations: (venueId: number, inquiryType?: InquiryType) => {
        // TODO: Migrate this to session store once we have API for it
        let visitedVenues = LocalStorage.getItem(StorableDataKeys.VISITED_VENUES, []);

        dispatch(loadVenueRecommendationsAction(venueId, visitedVenues, 7, inquiryType));
    },
    loadHotelRecommendations: (venueId: number) => {
        dispatch(loadHotelRecommendationsAction(venueId));
    },
    navigateToRecommendations: () => {
        dispatch(createTransitionRecommendationsAction());
    },
    navigateToHotelRecommendations: () => {
        dispatch(createTransitionHotelRecommendationsAction());
    },
    navigateToHotelForm: () => {
        dispatch(createTransitionHotelRecommendationsFormAction());
    },
    navigateToAbandonment: () => {
        dispatch(createTransitionAbandonmentAction());
    },
    navigateToSuccessScreen: () => {
        dispatch(createTransitionSuccessScreenAction());
    },
    navigateToSeeNearbyHotelsScreen: () => {
        dispatch(createTransitionSeeNearbyHotelsScreenAction());
    },

    resetWidget: () => {
        dispatch(createResetModalAction());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(InquiryWidgetContainerContent);
