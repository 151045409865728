import * as React from 'react';
import { RatingProps } from '../../csn/Rating';
import { Checkbox } from '@cvent/carina/components/Checkbox';

const styles = require('./Cards.module.scss');

export interface CheckoutVenueRecommendationsCardInterface {
    id: number | string; // number for venues and string for hotels (csnId)
    title: string;
    location: string;
    image?: string;
    isChecked?: boolean;
    checkedLabel?: string;
    rating?: RatingProps;
    pin?: string;
    type?: string;
}

interface CardPropsInterface {
    card: CheckoutVenueRecommendationsCardInterface;
    changeIsChecked: (id: number | string) => void;
}

const CheckoutVenueRecommendationsCard = ({ card, changeIsChecked }: CardPropsInterface) => {
    const { title, location, image, isChecked, checkedLabel, pin, id } = card;
    return (
        <div className={styles.checkoutCard}>
            <div className={styles.imageBlock}>
                <img src={image} alt={`venue ${title} thumbnail`} />
                {pin && <span className={styles.pin}>{pin}</span>}
            </div>
            <h3 className={styles.title}>{title}</h3>
            <p>{location}</p>
            <div className={styles.checkbox}>
                <Checkbox
                    id={'checkoutCardCheckbox'}
                    checked={isChecked}
                    onBlur={() => {}}
                    onChange={() => {
                        changeIsChecked(id);
                    }}
                >
                    {checkedLabel}
                </Checkbox>
            </div>
        </div>
    );
};

export default CheckoutVenueRecommendationsCard;
