import * as React from 'react';

import OutreachPushForm, { FormData } from './OutreachPushForm';

export interface BasicOutreachPushFormContainerProps {
    children?: React.ReactNode;
    venueIds: number[];
    seedVenueId?: number;
    venueCSNIds?: string[];
    unselectedPos?: number[];
    handleRender?: () => void;
    handleRejectExtraOutreach: () => void;
    handleExtraOutreachSuccess: (positions: number[]) => void;
    inquiryFormData?: object;
    userToken?: string;

    primaryCopy: string;
    secondaryCopy?: string;

    hotelsLoading?: boolean;
}

interface OwnProps {
    submitHandler: (venueIds: number[], checkedPos: number[], venueCSNIds?: string[]) => void;
    form: string;
}

type AllOutreachPushFormContainerProps = BasicOutreachPushFormContainerProps & OwnProps;

const submitHandlerGenerator = (props: AllOutreachPushFormContainerProps) => {
    return (data: FormData) => {
        const venueIds: number[] = [];
        const venueCSNIds: string[] = [];
        const checkedPos: number[] = [];

        if (data.get_pricing_1 && props.venueIds[0]) {
            venueIds.push(props.venueIds[0]);
        }
        if (data.get_pricing_2 && props.venueIds[1]) {
            venueIds.push(props.venueIds[1]);
        }
        if (data.get_pricing_3 && props.venueIds[2]) {
            venueIds.push(props.venueIds[2]);
        }
        if (data.get_pricing_4 && props.venueIds[3]) {
            venueIds.push(props.venueIds[3]);
        }
        if (data.get_pricing_5 && props.venueIds[4]) {
            venueIds.push(props.venueIds[4]);
        }
        if (data.get_pricing_6 && props.venueIds[5]) {
            venueIds.push(props.venueIds[5]);
        }

        if (data.get_pricing_1 && props.venueIds[0]) {
            checkedPos.push(1);
        }
        if (data.get_pricing_2 && props.venueIds[1]) {
            checkedPos.push(2);
        }
        if (data.get_pricing_3 && props.venueIds[2]) {
            checkedPos.push(3);
        }
        if (data.get_pricing_4 && props.venueIds[3]) {
            checkedPos.push(4);
        }
        if (data.get_pricing_5 && props.venueIds[4]) {
            checkedPos.push(5);
        }
        if (data.get_pricing_6 && props.venueIds[5]) {
            checkedPos.push(6);
        }

        for (let i = 1; i <= 6; i++) {
            if (data[`get_pricing_${i}`] && props.venueCSNIds && props.venueCSNIds[i - 1]) {
                venueCSNIds.push(props.venueCSNIds[i - 1]);
                checkedPos.push(i);
            }
        }

        props.submitHandler(venueIds, checkedPos, venueCSNIds);
    };
};

const BasicOutreachPushFormContainer: React.SFC<AllOutreachPushFormContainerProps> = (props) => {
    const initialValues = {
        // TODO probably need replace this with something like this
        // https://redux-form.com/8.2.2/examples/fieldarrays/
        get_pricing_1: true,
        get_pricing_2: true,
        get_pricing_3: true,
        get_pricing_4: true,
        get_pricing_5: true,
        get_pricing_6: true,
    };

    // unselect venues with dedicated prop
    if (!!props.unselectedPos) {
        props.unselectedPos.map((pos) => {
            if (pos >= 1 || pos <= 4) {
                initialValues['get_pricing_' + pos] = false;
            }
        });
    }

    // mark fields as unselected if some cells/venues are present
    if (props.venueIds.length > 0) {
        for (let i = props.venueIds.length + 1; i <= 6; i++) {
            initialValues['get_pricing_' + i] = false;
        }
    }

    // mark fields as unselected if some cells/venues are present
    // hotels case
    if (props.venueCSNIds?.length) {
        for (let i = props.venueCSNIds.length + 1; i <= 6; i++) {
            initialValues['get_pricing_' + i] = false;
        }
    }

    return (
        <div className='OutreachPushFormContainer'>
            {(props.venueIds.length || (props.venueCSNIds && props.venueCSNIds.length)) && (
                <OutreachPushForm initialValues={initialValues} {...props} submitHandler={submitHandlerGenerator(props)} />
            )}
        </div>
    );
};

export default BasicOutreachPushFormContainer;
