import * as React from 'react';
import { Venues, VendorsApi } from '@weddingspot/ws-api-client';
import BasicOutreachPushFormContainer, { BasicOutreachPushFormContainerProps } from './BasicOutreachPushFormContainer';
import { generateSRFPurl } from '../../utils/url';

interface OwnProps {
    showWsRfpForm?: boolean;
}

function OutreachHotelsFormContainer(props: React.PropsWithChildren<BasicOutreachPushFormContainerProps> & OwnProps) {
    React.useEffect(() => {
        props.handleRender && props.handleRender();
        if (props.venueCSNIds && props.seedVenueId) {
            VendorsApi.logHotelRecommendationImpression({
                ws_venue_id: props.seedVenueId,
                csn_venue_ids: props.venueCSNIds,
                source: Venues.HotelRecommendationImpressionSource.INQUIRY_MODAL,
            });
        }
    }, []);

    return (
        <BasicOutreachPushFormContainer
            {...props}
            submitHandler={(venueIds, checkedPos, venueCSNIds) => {
                !!(venueCSNIds && !props.showWsRfpForm) && window.open(generateSRFPurl(venueCSNIds));
                props.handleExtraOutreachSuccess(checkedPos);
            }}
            form='OutreachHotelsForm'
        />
    );
}

export default OutreachHotelsFormContainer;
